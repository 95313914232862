import Link from "../../../../components/Link/Link";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, title, imgSrc, href }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<img className={styles.img} src={imgSrc} />
			<Link className={styles.title} href={href}>
				{title}
			</Link>
		</div>
	);
};
