import { useEffect, useMemo, useRef } from "react";
import { animated, useSpringValue } from "react-spring";
import Typing from "./components/Typing/Typing";
import styles from "./styles.module.css";

export default ({ isAuthor, isBottom, isTop, text, style, isAction, isTyping, date }) => {
	const timestamp = useMemo(
		$ => {
			if (!date) {
				return;
			}

			const hours = date.getHours().toString().padStart(2, "0");
			const minutes = date.getMinutes().toString().padStart(2, "0");
			return `${hours}:${minutes}`;
		},
		[date],
	);

	const myRef = useRef();

	const myTextOpacity = useSpringValue(0);

	useEffect(
		$ => {
			if (!isTyping) {
				myTextOpacity.start(1, { config: { duration: 300 } });
			}
		},
		[myRef.current, text, isTyping],
	);

	const FakeElement = (
		<animated.div
			className={styles.container}
			style={{
				flexDirection: isAuthor ? "row" : "row-reverse",
				alignSelf: isAuthor ? "flex-end" : "flex-start",
				marginBottom: isBottom ? 0 : "0.25rem",
				position: "fixed",
				opacity: 0,
				top: "10rem",
				...style,
			}}
			ref={myRef}>
			<div
				className={styles.wrapper}
				style={{
					borderBottomRightRadius: isAuthor && isBottom ? 0 : isAuthor && !isBottom ? "0.375rem" : "0.75rem",
					borderBottomLeftRadius: !isAuthor && isBottom ? 0 : !isAuthor && !isBottom ? "0.375rem" : "0.75rem",
					borderTopRightRadius: isTop || !isAuthor ? "0.75rem" : "0.375rem",
					borderTopLeftRadius: isTop || isAuthor ? "0.75rem" : "0.375rem",
					backgroundColor: isAuthor ? "#786ccc" : "#282424",
				}}>
				<span
					className={styles.text}
					style={{
						fontStyle: isAction ? "italic" : "normal",
					}}>
					{isTyping ? <Typing /> : text}
				</span>
				<span className={styles.timestamp}>{timestamp}</span>
			</div>
			{
				<img
					src={isAuthor ? "/assets/chat/penis.png" : "/assets/chat/penisGray.png"}
					className={styles.penis}
					style={{
						transform: isAuthor ? "scale(1) translateX(-0.5px) translateY(0.8px)" : "scaleX(-1)",
						opacity: isBottom ? 1 : 0,
					}}
				/>
			}
		</animated.div>
	);

	return {
		myRef,
		FakeElement,
		myTextOpacity,
		timestamp,
	};
};
