export default ({ isAuthor, isBottom, isTop }) => ({
	conVoiceMessage: {
		flexDirection: isAuthor ? "row" : "row-reverse",
		alignSelf: isAuthor ? "flex-end" : "flex-start",
	},
	conWrapperDiv: {
		borderBottomRightRadius: isAuthor && isBottom ? 0 : isAuthor && !isBottom ? "0.375rem" : "0.75rem",
		borderBottomLeftRadius: !isAuthor && isBottom ? 0 : !isAuthor && !isBottom ? "0.375rem" : "0.75rem",
		borderTopRightRadius: isTop || !isAuthor ? "0.75rem" : "0.375rem",
		borderTopLeftRadius: isTop || isAuthor ? "0.75rem" : "0.375rem",
		backgroundColor: isAuthor ? "#786ccc" : "#282424",
		marginBottom: isBottom ? 0 : "0.25rem",
	},
	conPlayContainer: {
		backgroundColor: isAuthor ? "white" : "#786ccc",
	},
	conRect: {
		transition: "opacity 1s ease",
	},
	conPenis: {
		transform: isAuthor ? "scale(1)" : "scaleX(-1)",
		opacity: isBottom ? 1 : 0,
	},
});
