export const tier1Countries = [
	"United States",
	"Canada",
	"United Kingdom",
	"Australia",
	"Germany",
	"France",
	"Sweden",
	"Norway",
	"Denmark",
	"Finland",
	"Switzerland",
	"Netherlands",
	"New Zealand",
	"Ireland",
	"Belgium",
	"Austria",
	"Luxembourg",
	"Singapore",
	"Japan",
];

export default {
	tier1Countries,
};
