export const navJS = {
	toJSON: function () {
		var i = {
			userAgent: this.userAgent(),
			os: this.os(),
			browser: {
				name: this.browser(),
				version: this.version(),
				mobile: this.isMobile(),
				desktop: this.isDesktop(),
			},
			screen: {
				hd: this.isHD(),
				pixelRatio: this.pixelRatio(),
				landscape: this.isLandscape(),
				portrait: this.isPortrait(),
				touch: this.isTouch(),
			},
		};
		return (
			this.isIOS() &&
				(i.ios = {
					version: this.getIOSVersion(),
					device: this.isIPhone() ? "iphone" : this.isIPad() ? "ipad" : void 0,
				}),
			i
		);
	},
	os: function () {
		return this.isIOS()
			? "ios"
			: this.isAndroid()
			? "android"
			: this.isWindows()
			? "windows"
			: this.isUnix()
			? "unix"
			: this.isMac()
			? "mac"
			: this.isLinux()
			? "linux"
			: this.isBlackBerry()
			? "blackberry"
			: void 0;
	},
	browser: function () {
		return this.isSafari()
			? "safari"
			: this.isChrome()
			? "chrome"
			: this.isIE()
			? "ie"
			: this.isEdge()
			? "edge"
			: this.isFirefox()
			? "firefox"
			: this.isOpera()
			? "opera"
			: void 0;
	},
	userAgent: function () {
		return navigator.userAgent;
	},
	browserName: function () {
		var i,
			e = this.userAgent(),
			t = e.match(/(fxios|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(t[1])) return (i = /\brv[ :]+(\d+)/g.exec(e) || []), "IE " + (i[1] || "");
		if ("Chrome" === t[1])
			if ((i = e.match(/(edge(?=\/))\/?\s*(\d+)/i))) t = i;
			else if (((i = e.match(/\bOPR\/(\d+)/)), null != i)) return "Opera " + i[1];
		return (
			(t = t[2] ? [t[1], t[2]] : [navigator.appName, navigator.appVersion, "-?"]),
			null != (i = e.match(/version\/(\d+)/i)) && t.splice(1, 1, i[1]),
			t.join(" ")
		);
	},
	isMobile: function () {
		return (
			null !=
			this.userAgent().match(/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|CRiOS|OPiOS|Mobile|FxiOS/i)
		);
	},
	isDesktop: function () {
		return !this.isMobile();
	},
	isSafari: function () {
		return (
			-1 != this.userAgent().indexOf("Safari") &&
			-1 != navigator.vendor.indexOf("Apple Computer") &&
			!this.isChrome() &&
			!this.isFirefox() &&
			!this.isOpera()
		);
	},
	isChrome: function () {
		return (
			((-1 != this.userAgent().indexOf("Chrome") && -1 != navigator.vendor.indexOf("Google Inc")) ||
				-1 != this.userAgent().indexOf("CriOS")) &&
			!this.isOpera()
		);
	},
	isIE: function () {
		return (
			null !=
			this.browserName()
				.toLowerCase()
				.match(/ie|msie|iemobile/i)
		);
	},
	isEdge: function () {
		return null != this.browserName().toLowerCase().match(/edge/i);
	},
	isFirefox: function () {
		return (
			null !=
			this.browserName()
				.toLowerCase()
				.match(/firefox|fxios/i)
		);
	},
	isOpera: function () {
		return (
			null !=
				this.userAgent()
					.toLowerCase()
					.match(/opera|opr|opera mini|opios/i) ||
			"[object OperaMini]" === Object.prototype.toString.call(window.operamini)
		);
	},
	isMobileChrome: function () {
		return null != this.userAgent().toLowerCase().match("crios") || (this.isChrome() && this.isMobile());
	},
	isMobileSafari: function () {
		return this.isMobile() && this.isSafari();
	},
	isMobileIE: function () {
		return this.isMobile() && this.isIE();
	},
	isMobileOpera: function () {
		return this.isMobile() && this.isOpera();
	},
	isMobileFirefox: function () {
		return this.isMobile() && this.isFirefox();
	},
	isHD: function () {
		return window.devicePixelRatio ? window.devicePixelRatio && window.devicePixelRatio > 1 : !1;
	},
	pixelRatio: function () {
		return window.devicePixelRatio ? window.devicePixelRatio : 1;
	},
	isIOS: function () {
		return null != this.userAgent().match(/iPhone|iPad|iPod/i);
	},
	isAndroid: function () {
		return null != this.userAgent().match(/Android/i);
	},
	isWindows: function () {
		return null != this.userAgent().match(/Windows/i);
	},
	isUnix: function () {
		return null != this.userAgent().match(/Unix/i);
	},
	isMac: function () {
		return null != this.userAgent().match(/Mac/i) && !this.isIOS();
	},
	isLinux: function () {
		return null != this.userAgent().match(/Linux/i) && !this.isAndroid();
	},
	isBlackBerry: function () {
		return null != this.userAgent().match(/BlackBerry/i);
	},
	isIPad: function () {
		return (isIPad = /ipad/gi.test(navigator.platform)), isIPad;
	},
	isIPhone: function () {
		return (isIPad = /iphone/gi.test(navigator.platform)), isIPad;
	},
	isLandscape: function () {
		return window.innerHeight < window.innerWidth ? !0 : !1;
	},
	isPortrait: function () {
		return this.isLandscape();
	},
	getIOSVersion: function () {
		if (this.isIOS()) {
			var i = navigator.appVersion.match(/OS (\d+_\d+)/i);
			return (i = i[1] ? +i[1].replace("_", ".") : 0);
		}
		return !1;
	},
	isStandAlone: function () {
		return navigator.standalone ? navigator.standalone : !1;
	},
	version: function () {
		return this.browserName().replace(/^\D+/g, "");
	},
	maxTouchPoints: function () {
		return navigator.maxTouchPoints ? navigator.maxTouchPoints : void 0;
	},
	isTouch: function () {
		return "ontouchstart" in document.documentElement;
	},
	isPWA: function () {
		return (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone ||
			document.referrer.includes("android-app://")
		);
	},
};

export default { navJS };
