import { useSpring } from "react-spring";
import { useEffect, useState } from "react";

export default ({ beforeImage, afterImage }) => {
	const [flip, set] = useState(false);

	const [areImagesLoaded, setAreImagesLoaded] = useState(false);

	const width = "min(60vw, 20rem)";
	const height = `calc(${width} * 1.24958949097)`;

	const { position } = useSpring({
		from: { position: 100 },
		to: { position: 0 },
		reset: true,
		reverse: flip,
		delay: 200,
		config: {
			duration: 5000,
			easing: t => Math.cos((t + 1) * Math.PI) / 2 + 0.5,
		},
		onRest: () => set(!flip),
	});

	const styleAfter = {
		width: "auto",
		height: `100%`,
		position: "absolute",
		top: "0",
		zIndex: 1,
		border: "4px solid #7152E8",
		borderRadius: ".3rem",
	};

	const styleBefore = {
		width: "auto",
		height: `100%`,
		objectFit: "cover",
		clipPath: position.interpolate(position => `polygon(0 0, 100% 0, 100% ${position}%, 0 ${position}%)`),
		position: "absolute",
		top: "0",
		zIndex: 2,
		border: "4px solid #7152E8",
		borderRadius: ".3rem",
	};

	const styleLine = {
		position: "absolute",
		bottom: position.interpolate(position => `calc(${100 - position}% - 4px)`),
		width: position.interpolate(position => `${100 - Math.abs(position - 50) / 5}%`),
		height: "2px",
		backgroundColor: "white",
		zIndex: 3,
	};

	useEffect(
		$ => {
			setAreImagesLoaded(false);

			let beforeLoaded = false;
			let afterLoaded = false;

			const beforeImageObj = new Image();
			const afterImageObj = new Image();

			beforeImageObj.src = beforeImage;
			afterImageObj.src = afterImage;

			beforeImageObj.onload = $ => {
				beforeLoaded = true;

				if (beforeLoaded && afterLoaded) {
					setAreImagesLoaded(true);
				}
			};
			afterImageObj.onload = $ => {
				afterLoaded = true;

				if (beforeLoaded && afterLoaded) {
					setAreImagesLoaded(true);
				}
			};
		},
		[beforeImage, afterImage],
	);

	return {
		flip,
		set,
		width,
		position,
		styleAfter,
		styleBefore,
		styleLine,
		areImagesLoaded,
		height,
	};
};
