import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ beforeSrc, afterSrc, style, classNames }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={style}>
			<img
				src={beforeSrc}
				className={classNamesModule(styles.image, styles.imageBefore)}
				loading="lazy"
				alt="Undress AI deepnude example before"
				title="Before the Undress AI deepnude process"
			/>
			<div className={styles.arrow}>➡️</div>
			<img
				src={afterSrc}
				className={classNamesModule(styles.image, styles.imageAfter)}
				loading="lazy"
				alt="Undress AI deepnude example after"
				title="After the Undress AI deepnude process"
			/>
		</div>
	);
};
