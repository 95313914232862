import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import Popup from "../../../../components/Popup/Popup";
import LoginButton from "../../../../components/LoginButtons/LoginButtons";
import Button from "../../../../components/Button/Button";
import useLoginPopup from "./useLoginPopup";
import LoginButtons from "../../../../components/LoginButtons/LoginButtons";
import Link from "../../../../components/Link/Link";

export default ({ isOpen, closeOnOutsideClick, onClose, onLogin, classNames }) => {
	const {} = useLoginPopup();

	const [t] = useTranslation("global");

	return (
		<Popup
			className={classNamesModule(styles.container, ...(classNames || []))}
			isOpen={isOpen}
			closeOnOutsideClick={closeOnOutsideClick}
			onClose={onClose}>
			<div className={styles.text}>
				By continuing in, you confirm that you are over 18 years old, have the rights to use the photo and
				accept the <Link href="/user-agreement">user agreement</Link>. You are solely responsible for the images
				you create, we are NOT liable for any content created.
			</div>
			<div className={styles.loginButtons}>
				<LoginButtons />
			</div>
		</Popup>
	);
};
