import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/Button/Button";
import Popup from "../../../../components/Popup/Popup";
import styles from "./styles.module.css";
import LoginButtons from "../../../../components/LoginButtons/LoginButtons";

export default ({ isOpen, classNames, onClose }) => {
	const [t] = useTranslation("global");

	return (
		<Popup className={classNamesModule(...(classNames || []))} isOpen={isOpen} onClose={onClose}>
			<div className={styles.title}>🔞 Before you continue</div>
			<div className={styles.description}>
				By using this website, you confirm you are 18 or older, won't share photos created on this site, and
				understand that trying to create photos of minors will result in legal action. You also affirm that you
				have the rights to use the photos you upload to create new photos on our website. You won't use the site
				for harmful purposes.
			</div>
			<div className={styles.buttonContainer}>
				<LoginButtons />
			</div>
		</Popup>
	);
};
