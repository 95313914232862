export default ({ isAuthor, isBottom, isTop, text, onCensoredClick }) => ({
	conContainer: {
		justifyContent: isAuthor ? "flex-end" : "flex-start",
		// alignSelf: isAuthor ? "flex-end" : "flex-start",
		marginBottom: isBottom ? 0 : "0.25rem",
	},
	conWrapper: {
		borderTopRightRadius: isTop || !isAuthor ? "0.75rem" : "0.375rem",
		borderTopLeftRadius: isTop || isAuthor ? "0.75rem" : "0.375rem",
		borderBottomRightRadius: text
			? 0
			: isAuthor && isBottom
			? "0.375rem"
			: isAuthor && !isBottom
			? "0.375rem"
			: "0.75rem",
		borderBottomLeftRadius: text
			? 0
			: !isAuthor && isBottom
			? "0.375rem"
			: !isAuthor && !isBottom
			? "0.375rem"
			: "0.75rem",
	},
	conPhotoView: {
		...(onCensoredClick ? { filter: "blur(5px)" } : {}),
	},
	conContentContainer: {
		borderBottomRightRadius: isAuthor && isBottom ? 0 : isAuthor && !isBottom ? "0.375rem" : "0.75rem",
		borderBottomLeftRadius: !isAuthor && isBottom ? 0 : !isAuthor && !isBottom ? "0.375rem" : "0.75rem",
		backgroundColor: isAuthor ? "#786ccc" : "#282424",
		// justifyContent: isAuthor ? "flex-end" : "flex-start",
	},
	conImg: {
		transform: isAuthor ? "scale(1)" : "scaleX(-1)",
		opacity: isBottom ? 1 : 0,
	},
});
