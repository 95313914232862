import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style }) => {
	const faqs = [
		{
			question: "What is a sexting AI girlfriend?",
			answer: "A sexting AI girlfriend is a computer program designed to simulate conversation and interaction with a human, specifically focusing on romantic or intimate content. Think of it like a chatbot friend you can text with, but it's programmed to talk about lovey-dovey or flirty stuff. It uses artificial intelligence (AI) to understand and respond to messages, trying to mimic how a real person might talk in a romantic context.",
		},
		{
			question: "Can this AI girlfriend send photos?",
			answer: "Yes, on our website you can not only chat with your AI crush, but also receive images. The photos will look extremely similar to the real person in the photos you provided when creating your AI crush.",
		},
		{
			question: "Can my ai girlfriend send voice messages?",
			answer: "Yes! On our platform, you can also get voice messages from your AI girlfriend.",
		},
		{
			question: "What types of photos can my AI girl send?",
			answer: "Your slutty AI crush can send all types of images. Including selfies, pussy photos, photos where she is getting fucked and more.",
		},
		{
			question: "Is the Chat and the photos censored?",
			answer: "All photos are fully uncensored and there are no limits about what your AI girl can talk about. Nothing is censored or filtered.",
		},
		{
			question: "Is chat with my AI crush free?",
			answer: "Yes, there is a free tier available that let’s you try it without any cost.",
		},
		{
			question: "Are my conversations private?",
			answer: "Yes, your all your conversations and photos are fully encrypted and only accessible by you.",
		},
	];

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<h2 className={styles.title}>Frequently asked questions</h2>
			{faqs.map(({ question, answer }) => (
				<>
					<h3 className={styles.question}>{question}</h3>
					<p className={styles.answer}>{answer}</p>
				</>
			))}
		</div>
	);
};
