import SlideRoutes from "react-slide-routes";

import { Route } from "react-router-dom";
// import SelectPhoto from "../NewUndress/pages/SelectPhoto/SelectPhoto";
import SelectPhoto from "./components/SelectPhoto/SelectPhoto";
import styles from "./styles.module.css";
import Footer from "../../components/Footer/Footer";
import useFunnel from "./useFunnel";
import Popup from "./components/Popup/Popup";
import FunnelUndressing from "../FunnelUndressing/FunnelUndressing";
import FunnelResult from "../FunnelResult/FunnelResult";
import Comment from "./components/Comment/Comment";
import { Flipper, Flipped } from "react-flip-toolkit";
import { useState } from "react";
import { shuffle } from "lodash";
import Comments from "./components/Comments/Comments";
import HeadSeo from "../../components/HeadSeo/HeadSeo";

const Funnel = ({
	selectedUndressPhoto,
	setSelectedUndressPhoto,
	showSelectRandomGirlPopup,
	tryWithRandomGirl,
	handleOnCloseChooseRandomGirlModal,
	sliderRef,
	handleNavigateNextImage,
	handleNavigatePrevImage,
	handleChooseOwnImage,
	handleUndress,
	randomGirlsList,
	handleChooseRandomGirl,
	commentsObj,
	isFamilyFriendly,
}) => {
	const [data, setData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
	const shuffleList = () => setData(shuffle(data));

	return (
		<div className={styles.container}>
			<HeadSeo noIndex={true} title="Advanced AI" description="Our advanced AI makes the impossible possible" />
			<SelectPhoto
				selectedUndressPhoto={selectedUndressPhoto}
				setSelectedUndressPhoto={setSelectedUndressPhoto}
				title={isFamilyFriendly ? " " : "Remove clothes from any photo 😲"}
				description={
					isFamilyFriendly ? " " : "Simply upload a photo and our AI will remove the clothes from it"
				}
				handleChooseOwnImage={handleChooseOwnImage}
				uploadButtonText={isFamilyFriendly ? "Upload Photo 😉" : "Upload photo 💦"}
			/>
			<section className={styles.findGirlsSection}>
				{!isFamilyFriendly && <div className={styles.description}>Find girls to undress at</div>}
				{!isFamilyFriendly && (
					<div className={styles.findGirlsIconsContainer}>
						<img src="/assets/funnel/socialmediatograbgirlphotos.png" className={styles.findGirlsIcons} />
					</div>
				)}
				<div className={styles.tryFindingGirlsContainer} onClick={tryWithRandomGirl}>
					<img src="/assets/funnel/dice.png" className={styles.findGirlsDiceIcon} alt="" />
					<h6 className={styles.tryFindingGirlsTitle}>
						Try with random {isFamilyFriendly ? "photo" : "girl"}
					</h6>
				</div>
			</section>
			<Comments commentsObj={commentsObj} />
			<Popup
				isOpen={showSelectRandomGirlPopup}
				handleChooseOwnImage={handleChooseOwnImage}
				handleUndress={handleUndress}
				handleOnCloseChooseRandomGirlModal={handleOnCloseChooseRandomGirlModal}
				girls={randomGirlsList}
				sliderRef={sliderRef}
				handleNavigateNextImage={handleNavigateNextImage}
				handleNavigatePrevImage={handleNavigatePrevImage}
				onGirlChoose={handleChooseRandomGirl}
			/>
		</div>
	);
};

export default () => {
	const {
		selectedUndressPhoto,
		commentsObj,
		showSelectRandomGirlPopup,
		sliderRef,
		undressComplete,
		undressTypeIsRandom,
		isUndressing,
		scrollPosition,
		setSelectedUndressPhoto,
		setShowSelectRandomGirlPopup,
		tryWithRandomGirl,
		handleOnCloseChooseRandomGirlModal,
		handleNavigateNextImage,
		handleNavigatePrevImage,
		handleChooseOwnImage,
		handleUndress,
		setIsUndressing,
		setUndressComplete,
		setGirls,
		resetUpload,
		resultSrc,
		setResultSrc,
		randomGirlsList,
		handleChooseRandomGirl,
	} = useFunnel({});

	return (
		<SlideRoutes>
			<Route
				path="/funnel"
				element={
					<Funnel
						selectedUndressPhoto={selectedUndressPhoto}
						commentsObj={commentsObj}
						showSelectRandomGirlPopup={showSelectRandomGirlPopup}
						sliderRef={sliderRef}
						setSelectedUndressPhoto={setSelectedUndressPhoto}
						setShowSelectRandomGirlPopup={setShowSelectRandomGirlPopup}
						tryWithRandomGirl={tryWithRandomGirl}
						handleOnCloseChooseRandomGirlModal={handleOnCloseChooseRandomGirlModal}
						handleNavigateNextImage={handleNavigateNextImage}
						handleNavigatePrevImage={handleNavigatePrevImage}
						handleChooseOwnImage={handleChooseOwnImage}
						handleUndress={handleUndress}
						randomGirlsList={randomGirlsList}
						handleChooseRandomGirl={handleChooseRandomGirl}
						isFamilyFriendly={false}
					/>
				}
			/>
			<Route
				path="/family-funnel"
				element={
					<Funnel
						selectedUndressPhoto={selectedUndressPhoto}
						commentsObj={commentsObj}
						showSelectRandomGirlPopup={showSelectRandomGirlPopup}
						sliderRef={sliderRef}
						setSelectedUndressPhoto={setSelectedUndressPhoto}
						setShowSelectRandomGirlPopup={setShowSelectRandomGirlPopup}
						tryWithRandomGirl={tryWithRandomGirl}
						handleOnCloseChooseRandomGirlModal={handleOnCloseChooseRandomGirlModal}
						handleNavigateNextImage={handleNavigateNextImage}
						handleNavigatePrevImage={handleNavigatePrevImage}
						handleChooseOwnImage={handleChooseOwnImage}
						handleUndress={handleUndress}
						randomGirlsList={randomGirlsList}
						handleChooseRandomGirl={handleChooseRandomGirl}
						isFamilyFriendly={true}
					/>
				}
			/>
			<Route
				path="/funnel/undressing"
				element={
					<FunnelUndressing
						scrollPosition={scrollPosition}
						undressTypeIsRandom={undressTypeIsRandom}
						undressComplete={undressComplete}
						isUndressing={isUndressing}
						resetUpload={resetUpload}
						setGirls={setGirls}
						setUndressComplete={setUndressComplete}
						setIsUndressing={setIsUndressing}
						selectedUndressPhoto={selectedUndressPhoto}
						resultSrc={resultSrc}
						setResultSrc={setResultSrc}
						commentsObj={commentsObj}
					/>
				}
			/>
			<Route
				path="/funnel/result"
				element={
					<FunnelResult
						undressTypeIsRandom={undressTypeIsRandom}
						isUndressing={isUndressing}
						undressComplete={undressComplete}
						resetUpload={resetUpload}
						resultSrc={resultSrc}
						commentsObj={commentsObj}
					/>
				}
			/>
		</SlideRoutes>
	);
};
