import axios from "axios";
import constants from "../constants";
import axiosRetry from "axios-retry";

const axiosInstance = axios.create({
	baseURL: constants.api.API_SERVER,
});

axiosRetry(axiosInstance, { retries: 15, retryDelay: $ => 1000 });

axiosInstance.interceptors.request.use(config => {
	const rawTtoken = localStorage.getItem("ttoken");
	const rawAccessToken = localStorage.getItem("oauthAccessToken");
	const rawRefreshToken = localStorage.getItem("oauthRefreshToken");
	const rawAuthType = localStorage.getItem("authMethod");
	const rawNoauthSessionID = localStorage.getItem("noauthSessionID");

	if (rawTtoken) {
		const ttoken = JSON.parse(rawTtoken);
		config.headers["x-ttoken"] = ttoken;
	}

	if (rawAccessToken) {
		const accessToken = JSON.parse(rawAccessToken);
		config.headers["x-oauth-access-token"] = accessToken;
	}

	if (rawRefreshToken) {
		const refreshToken = JSON.parse(rawRefreshToken);
		config.headers["x-oauth-refresh-token"] = refreshToken;
	}

	if (rawAuthType) {
		const authMethod = JSON.parse(rawAuthType);
		config.headers["x-auth-method"] = authMethod;
	}

	if (rawNoauthSessionID) {
		const noauthSessionID = JSON.parse(rawNoauthSessionID);
		config.headers["x-noauth-session-id"] = noauthSessionID;
	}

	config.headers["ngrok-skip-browser-warning"] = "yesdothat";

	return config;
});

axiosInstance.interceptors.response.use(response => {
	const newAccessToken = response.headers["x-new-oauth-access-token"];
	if (newAccessToken) {
		console.log({ newAccessToken });
		localStorage.setItem("oauthAccessToken", JSON.stringify(newAccessToken));
	}

	return response;
});

export default axiosInstance;
