export const get4basedDepositAmount = price => {
	return price <= 2.99
		? "at least 3"
		: price <= 10
		? "10"
		: price <= 20
		? "20"
		: price <= 50
		? "50"
		: price <= 100
		? "100"
		: price <= 300
		? "300"
		: price <= 500
		? "500"
		: price <= 1000
		? "1000"
		: price;
};

export const getDollarFormatted = amount => {
	// $1.99
	return amount && `$${amount.toFixed(2)}`;
};

export default {
	get4basedDepositAmount,
	getDollarFormatted,
};
