import styles from "./styles.module.css";
import classNamesModule from "classnames";
import useTimer from "./useTimer";

export default ({ classNames, style, duration, interval, keyID, children, hideMS }) => {
	const { formattedTime } = useTimer({ duration, interval, keyID, hideMS });

	return (
		<span className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			{children}
			{formattedTime}
		</span>
	);
};
