import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import Button from "../../../Button/Button";

export default ({ classNames, onGetClick, onCloseClick }) => {
	const [t] = useTranslation("global");
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))}>
			<div className={styles.showcase}>
				<img className={styles.logo} src="/assets/random/appIcon.png" alt="Logo" />
				<div className={styles.textContainer}>
					<span className={styles.title}>{t("webAppInfo.undress_app")}</span>
					<span className={styles.description}>{t("webAppInfo.undress_easier_ste")}</span>
				</div>
			</div>
			<Button classNames={[styles.getButton]} textClassNames={[styles.getButtonText]} onClick={onGetClick}>
				{t("webAppInfo.get_now")}
			</Button>
		</div>
	);
};
