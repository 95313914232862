export const msUntilDate = date => {
	if (date == null) {
		return null;
	}

	const now = new Date();

	return date - now;
};

export const msSinceDate = date => {
	if (date == null) {
		return null;
	}

	const now = new Date();

	return now - date;
};

export const msToDays = ms => {
	if (ms == null) {
		return null;
	}

	return ms / 1000 / 60 / 60 / 24;
};

export const msToMinutes = ms => {
	if (ms == null) {
		return null;
	}

	return ms / 1000 / 60;
};

export const hoursToMs = hours => {
	if (hours == null) {
		return null;
	}

	return hours * 1000 * 60 * 60;
};

export const addMsToDate = ({ date, ms }) => {
	if (date == null || ms == null) {
		return null;
	}

	return new Date(date.getTime() + ms);
};

export const getTimestamp = date => {
	const hours = date.getHours().toString().padStart(2, "0");
	const minutes = date.getMinutes().toString().padStart(2, "0");

	return `${hours}:${minutes}`;
};

export const getTimeUntilNextSunday = () => {
	const now = new Date();
	const currentDay = now.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

	const daysUntilSunday = (7 - currentDay) % 7;

	const nextSunday = new Date(now);
	nextSunday.setDate(now.getDate() + daysUntilSunday);
	nextSunday.setHours(23, 59, 59, 0);

	const timeDifference = nextSunday - now;

	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

	let result = "";
	if (days > 0) {
		result += days === 1 ? "1 day " : `${days} days `;
	}
	result += `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
		.toString()
		.padStart(2, "0")}`;

	return result;
};

export const getMSUntilTomorrowUTC = () => {
	const now = new Date();
	// Create a date object in UTC
	const nowUTC = Date.UTC(
		now.getUTCFullYear(),
		now.getUTCMonth(),
		now.getUTCDate(),
		now.getUTCHours(),
		now.getUTCMinutes(),
		now.getUTCSeconds(),
		now.getUTCMilliseconds(),
	);
	// Tomorrow UTC at 00:00
	const tomorrowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1);
	// Calculate the milliseconds until tomorrow UTC
	const msUntilTomorrow = tomorrowUTC - nowUTC;

	return msUntilTomorrow;
};

export default {
	msUntilDate,
	msSinceDate,
	msToDays,
	hoursToMs,
	addMsToDate,
	getTimestamp,
	getTimeUntilNextSunday,
	msToMinutes,
	getMSUntilTomorrowUTC,
};
