import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, error }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<div className={styles.title}>An error occurred</div>
			<div className={styles.description}>
				Please copy the following error and send it to our livechat support. We will help you immediately.
			</div>
			<div className={styles.errorText}>
				Error: ERROR in {window.location.href} {error?.toString()} / Browser: {navigator.userAgent}
			</div>
			<Button
				onClick={$ => {
					window.location.href = "/upload?support";
				}}
				classNames={[styles.contactSupportButton]}>
				Contact livechat support
			</Button>
			<Button
				onClick={$ => {
					window.location.href = "/";
				}}
				isSecondary={true}>
				Homepage
			</Button>
		</div>
	);
};
