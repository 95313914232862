import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style }) => {
	const faqs = [
		{
			question: "How do i use undress AI deepnude?",
			answer: "You can simply upload the photo you want to nudify, press “undress” and the AI technology does all the work for you. You can also choose clothing types like “lingerie” and “bikini”, which makes it really fun to use :)",
		},
		{
			question: "What is undress AI?",
			answer: "Undress AI is a service which leverages deepnude technology to nudify any photo. You simply upload the photo and the undress ai system removes the clothes automatically. It’s really easy to use.",
		},
		{
			question: "Is undress ai safe?",
			answer: "Undress AI is completely safe. Nothing is stored on servers and your actions are not published anywhere.",
		},
		{
			question: "What if i need help?",
			answer: "If you need help or have any question, you can contact our livechat on our website. We are 24/7 available and are happy to help.",
		},
		{
			question: "Can I use Undress AI deefake on any image?",
			answer: "Undress AI is designed to work with a wide range of images, but for best results, use clear, high-resolution images without obstructions.",
		},
		{
			question: "Is there a mobile version or Undress AI APK?",
			answer: "Currently, Undress AI only has a web version. We are considering an app and will update our users when it becomes available.",
		},
		{
			question: "Does Undress AI clothes remover support multiple languages?",
			answer: "Currently, Undress AI supports English. We are working to include more languages in future updates.",
		},
		{
			question: "Is undress AI the best nudify deefake tool?",
			answer: "Undress AI is the most advanced nudify tool available. It’s fast, easy to use and highly secure. We are constantly improving our technology to provide the best experience for our users. There are many other nudify tools available, but keep in mind that not all of them are safe to use.",
		},
		{
			question: "Is there a trial version of Undress AI available?",
			answer: "Yes, we offer a trial version for new users to experience Undress AI. Simply create an account and you will be granted 30 free coins.",
		},
		{
			question: "Can I request a feature for Undress AI nudify tool?",
			answer: "Absolutely! We welcome user feedback. You can request features through our livechat and via email: support@undressai.com",
		},
	];

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<h2 className={styles.title}>Frequently asked questions</h2>
			{faqs.map(({ question, answer }) => (
				<>
					<h3 className={styles.question}>{question}</h3>
					<p className={styles.answer}>{answer}</p>
				</>
			))}
		</div>
	);
};
