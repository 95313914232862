export const titleSuffix = "Undress AI Deepnude App";
export const defaultTitle = "Undress AI: Nudify anyone with deepnude";
export const defaultDescription =
	"Undress AI anyone instantly. Just upload a photo, and the undress AI will removes the clothes within seconds. We are the best deepnude service, nudify right now!";
export const defaultOgImageAlt = "Best undress AI tools";
export const defaultOgLocale = "en_US";
export const defaultOgImage = "https://undressai.com/assets/random/otherplatforms.png";

export default {
	titleSuffix,
	defaultTitle,
	defaultDescription,
	defaultOgImageAlt,
	defaultOgLocale,
	defaultOgImage,
};
