export default ({ onClick }) => {
	const handleClick = e => {
		if (onClick) {
			onClick(e);
		}
	};

	return {
		handleClick,
	};
};
