import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { randomASCIIString } from "../../utils/string";
import { sleep } from "../../utils/promises";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "../../contexts/User/User";

export default () => {
	const sliderRef = useRef(null);
	const navigate = useNavigate();
	const [selectedUndressPhoto, setSelectedUndressPhoto] = useState(null);
	const [showSelectRandomGirlPopup, setShowSelectRandomGirlPopup] = useState(false);
	const [scrollPosition, setScrollPostion] = useState(0);

	const [undressComplete, setUndressComplete] = useState(false);
	const [isUndressing, setIsUndressing] = useState(false);
	const [undressTypeIsRandom, setUndressTypeIsRandom] = useState(false);

	const { setIsLoginPopupOpen } = useUser();

	const [hasUndressedForFreeBefore, setHasUndressedForFreeBefore] = useLocalStorage(
		"hasUndressedForFreeBefore",
		false,
	);

	const randomGirlsList = [1, 2, 3, 4, 5].map(num => ({
		beforeSrc: `../../assets/funnel/girls/${num}before.png`,
		resultSrc: `../../assets/funnel/girls/${num}after.png`,
	}));

	const [resultSrc, setResultSrc] = useState(null);

	const texts = [
		"I just tried it with my work colleague. This is the best AI ever!! 🔥",
		"Nono, i never used this ai.. or did I :)",
		"I always wanted to see her like this... now i can :)",
		"Wtf this actually works",
		"It works perfectly, but my girlfriend caught me using it 😡",
		"This is insane",
		"How is this possible?",
		"I can't believe this technology exists",
		"Wow, this is mind blowing",
		"My mind is officially blown 🤯",
		"I can't wait to see what else this AI can do!",
		"I'm totally addicted to this AI now 😍",
		"Thank you AI, you've made all my fantasies come true!",
		"I'm never gonna get anything done now that I have this AI 😂",
		"This is definitely the future of technology",
		"I'm going to have so much fun with this hahahaha",
		"I wonder what else this AI can do... 🤔",
		"Best invention ever!",
		"I can't believe I have access to this level of technology... 😮",
		"I'm going to have a hard time keeping this a secret from my friends...",
		"My Instagram game just got a whole lot hotter",
		"This is like having superpowers! 😎",
		"I feel like I'm living in a sci-fi movie... but better 😉",
		"AI, you da real MVP 👏🏼",
		"Mind blown x 100 💥",
		"I don't want to sound dramatic, but this AI might have changed my life forever 🙌🏼",
		"I never thought I'd have the power to create my own dream girl",
		"The future is truly here",
		"My jaw literally dropped when I saw the results",
		"I feel like a kid in a candy store, but instead of candy, it's beautiful people 😜",
		"I can't stop playing with this AI, it's too addicting",
		"This is like a virtual paradise",
		"I'm so grateful to live in a time where this type of technology exists",
		"My wildest dreams just became a reality thanks to this AI",
		"The possibilities are endless with this AI... my mind is racing with ideas",
		"I feel like I'm living in the Matrix, except I have full control",
		"My creative juices are flowing like never before",
		"This AI is the ultimate wingman",
		"I can't wait to show my friends this amazing discovery",
		"Is it possible to fall in love with an AI? 😍",
		"This is better than any photoshop or filters",
		"I'm definitely not getting any sleep tonight, thanks AI 🤪",
		"This AI is truly groundbreaking",
		"I'm impressed by how realistic the images are... the future is looking pretty amazing",
		"Who needs a partner when you have this AI? 😂",
		"I'm lowkey scared of how addicted I am to this AI already",
		"I never knew technology could be this advanced",
		"I can't believe how easy it is to use",
		"It's like a photo editor on steroids",
		"My creativity is on another level thanks to this AI",
		"I can see why this AI is making headlines, it's truly incredible",
		"I wish I had this technology when I was in high school 😅",
		"I can finally live out my fantasies without any consequences",
		"I feel like I have a secret superpower that nobody else knows about",
		"This AI should come with a warning label for how addicting it is",
		"I think I've found my new hobby 😂",
		"This is definitely not what my parents meant when they said 'work smarter, not harder'",
		"I can't believe I'm able to do this with just a few clicks... amazing",
		"It's like having my own personal digital genie, granting all my wishes",
		"Who needs a gym when I can just use this AI to give myself the perfect body? 💪🏼",
		"I can't wait to see the advancements this AI will make in the future",
		"This is definitely going to change the game for influencers and social media personalities",
		"I feel like I've unlocked a secret world with this AI",
		"I never knew I needed this until now... my life will never be the same",
		"This AI has the power to make anyone feel like a supermodel",
		"I can't stop smiling every time I use this AI",
		"This AI deserves all the recognition and awards, it's truly a game changer",
		"I'm already planning my next photo shoot with the help of this AI",
		"I never want to live in a world without this AI",
		"This AI is going to make long distance relationships a lot more interesting 😉",
		"I can't wait to see the reactions when people find out I used this AI on my photos",
		"I feel like I've discovered a hidden treasure with this AI",
		"I'm so glad I stumbled upon this amazing AI, my life will never be boring again",
		"I can finally create my own dream photoshoot with this AI",
		"This AI is proof that the impossible can become possible",
		"I feel like I'm cheating on my photography skills, but I can't help it 🤷🏼‍♀️",
		"This AI has the power to make anyone look like a model",
		"My Instagram feed is about to get a whole lot better, thanks to this AI",
		"I can't wait to see how this AI will evolve in the future",
		"My Instagram following is about to increase significantly with the help of this AI",
		"I never knew technology could make me feel so confident",
		"I can't believe this AI can actually make me look like a different person",
		"I'm definitely going to impress my crush with my new and improved photos 😉",
		"This is the best kept secret, and I'm so glad I know about it",
		"I'm excited to see how this AI will change the world of fashion and modeling",
		"I feel like I'm getting a behind-the-scenes look at a photo shoot every time I use this AI",
		"This AI is perfect for people like me who are not blessed with perfect skin or features",
		"The future looks pretty damn good with this AI in it 👌🏼",
		"I can't believe this AI is available for everyone to use, it's truly amazing",
		"This AI is about to take my social media game to a whole new level",
		"I wish I could thank the creators of this AI in person for their genius invention",
		"I can't believe how realistic and flawless the images look with the help of this AI",
		"I'm going to have a hard time explaining to people that my photos are not actually real 😂",
	];

	const authors = [
		"mark285",
		"thenightwolf",
		"kollegahderboss",
		"marvinlidk2",
		"lars_dominik",
		"set1koehle",
		"drserhatmutluer",
		"georg.schlafen",
		"drissatlkhato",
		"vanilairini",
		"iamcasum",
		"h.yucedal",
		"miss.bbunnyyy",
		"caraxenax",
		"hgc_castings",
		"martinsonmarcus",
		"anja_zolo",
		"mariankaif",
		"fvckpoponline",
		"boxa91",
		"jdchristiani",
		"jamkassl",
		"carinarosson",
		"100bandgang",
		"thiiago27",
		"plauer_city",
		"riano_ocean",
		"leeyoniie",
		"bjoerntraeger",
		"hana.sampayo",
		"blackoctane82",
		"ko_yomi27",
		"botti_2000",
		"cemileuskans",
		"clanshamansm",
		"carmenmario21",
		"ebee_kose",
		"someonestupid",
		"coolvionka",
		"carlosmvasquez",
		"theo_revax",
		"karinamilaya",
		"itsme_lexxyyy",
		"kaffeekenieken",
		"hayaluxena",
		"peba7609",
		"nepuqena",
		"schule_futuristic",
		"abbylovessavage",
		"jennyyto87",
		"boomibestiboii",
		"merklungenheiligenschrein",
		"carolinaeabc7",
		"flo_franna",
		"ragingbell22",
		"ricksss",
		"lowkey_muffinz",
		"emanuellomafr",
		"backomdaweek",
		"mona_riccie",
		"cecilandermerss",
		"dbphaze2",
		"wyverbella",
		"duosplit",
		"dp_will_lingskz",
		"emirkessemmm",
		"3_bcc",
		"anoninkalol",
		"fcancer21",
		"stellaquoe",
		"chasofiiony",
		"felicia_klinton",
		"maxoi050501",
		"biocher___",
		"whoslondon",
		"luixvilles",
		"njr_workaholic",
		"alanaxmatias",
		"musicorn88",
		"roweeceyan",
		"penado_play",
		"bugdoc26",
		"lustandbabel",
		"yemis_unver",
		"1min1geburttagebuch",
		"studio.hope.pro",
		"roboyilideli",
		"ryc_asuedemmy",
		"berry._29",
		"emlynautoclucendikica",
		"who_go_nevermind",
		"adelina.bendina",
		"rehponta",
		"seyterdark03",
		"_theblindmonster",
		"luckkadiinz_nfbrojaa",
		"filipstheaven",
		"nickyliththist",
		"give_it_done",
		"flirter18",
		"shevalawyers3",
		"rudedisease_",
		"have_unseen",
		"tanamyamarzano",
		"keverslive",
		"crowdgittamilbees",
		"eyeupancyllips83",
		"dolorigerald",
		"personagensparadesenhos",
		"willborohnesterly",
		"dl_fromthe8",
		"prettylost33",
		"__bimboking__",
		"g.bracialove2",
		"beniodurao70",
		"nalchemype",
		"conzepy_algvenary",
		"crglssmzkimx1111",
		"bflaah",
		"mischa_di",
		"sexywhite_beauty",
		"beautiful_ocepounious13",
		"baldurskornypalo",
		"vita56mas_benjoab02",
		"said_sassenne36",
	];

	const [commentsObj, setCommentsObj] = useLocalStorage(
		"commentsObj",
		texts.slice(0, 5).map((comment, i) => ({
			text: comment,
			author: authors[i] || _.sample(authors),
		})),
	);

	const tryWithRandomGirl = () => {
		setUndressComplete(false);

		// setGirls(randomGirlsList);
		// setScrollPostion(0);
		setShowSelectRandomGirlPopup(!showSelectRandomGirlPopup);
	};

	const addNewComments = async $ => {
		while (1) {
			setCommentsObj(before => {
				const newText = texts.find(text => !before.find(c => c.text === text));

				if (!newText) {
					return before;
				}

				const newComment = { text: newText, author: _.sample(authors) };

				return [...before, newComment];
			});

			await sleep(1000);

			setCommentsObj(before => {
				const lastComment = _.last(before);

				return [lastComment, ...before.filter(x => x !== lastComment)];
			});

			await sleep(_.random(4000, 10_000));
		}
	};

	useEffect($ => {
		addNewComments();
	}, []);

	const handleOnCloseChooseRandomGirlModal = () => {
		setShowSelectRandomGirlPopup(false);
	};

	const handleChooseOwnImage = e => {
		if (hasUndressedForFreeBefore) {
			setIsLoginPopupOpen(true);
			return;
		}

		setHasUndressedForFreeBefore(true);

		const selectedFile = e.target.files[0];
		setSelectedUndressPhoto(selectedFile);

		setIsUndressing(true);
		setUndressComplete(false);

		navigate("/funnel/undressing");
	};

	const handleChooseRandomGirl = ({ girl }) => {
		setIsUndressing(true);
		setUndressComplete(false);

		navigate(`/funnel/undressing?before_src=${girl.beforeSrc}&result_src=${girl.resultSrc}`);
	};

	const resetUpload = () => {
		setUndressComplete(false);
		setShowSelectRandomGirlPopup(false);

		navigate("/funnel");
	};

	const handleUndress = type => {};

	return {
		selectedUndressPhoto,
		setSelectedUndressPhoto,
		commentsObj,
		tryWithRandomGirl,
		showSelectRandomGirlPopup,
		setShowSelectRandomGirlPopup,
		handleOnCloseChooseRandomGirlModal,
		sliderRef,
		handleChooseOwnImage,
		handleUndress,
		undressComplete,
		undressTypeIsRandom,
		isUndressing,
		setIsUndressing,
		setUndressComplete,
		resetUpload,
		scrollPosition,
		resultSrc,
		setResultSrc,
		randomGirlsList,
		handleChooseRandomGirl,
	};
};
