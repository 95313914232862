import Footer from "../../components/Footer/Footer";
import Link from "../../components/Link/Link";
import TopDeepnudeApps from "./articles/TopDeepnudeApps/TopDeepnudeApps";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, children }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<Link href="/">
				<img className={styles.logo} src="/assets/undresslanding/logo.webp" alt="undress ai logo" />
			</Link>
			{children}
			<Footer />
		</div>
	);
};
