import Button from "../../../../../../components/Button/Button";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({
	classNames,
	style,
	emoji,
	title,
	description,
	onClick,
	ctaButtonClassNames,
	ctaButtonTextClassNames,
}) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<div className={styles.emoji}>{emoji}</div>
			<h2 className={styles.title}>{title}</h2>
			<p className={styles.description}>{description}</p>
			<Button
				classNames={[styles.ctaButton, ...(ctaButtonClassNames || [])]}
				textClassNames={[styles.ctaButtonText, ...(ctaButtonTextClassNames || [])]}
				onClick={onClick}>
				Create Deepfake Porn
			</Button>
		</div>
	);
};
