import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default ({ resetUpload }) => {
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const imageRef = useRef(false);

	const [searchParams] = useSearchParams();

	const isRandomGirl = searchParams.get("is_random_girl") === "true";

	const handleOnCloseChooseRandomGirlModal = () => {
		setShowSelectRandomGirlPopup(false);
	};

	const handleLoginPopupClose = () => {
		setShowLoginPopup(false);
	};

	const handleDownloadOrRemoveBlur = () => {
		setShowLoginPopup(true);
	};

	const handleUndressAnotherOrDownload = () => {
		if (isRandomGirl) {
			resetUpload();
			return;
		}

		handleDownloadOrRemoveBlur();
	};

	return {
		showLoginPopup,
		handleOnCloseChooseRandomGirlModal,
		handleLoginPopupClose,
		handleDownloadOrRemoveBlur,
		handleUndressAnotherOrDownload,
		imageRef,
		isRandomGirl,
	};
};
