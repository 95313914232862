import i18n from "i18next";

import en_translation from "./en/en.json";
import gr_translation from "./gr/gr.json";
import fr_translation from "./fr/fr.json";

i18n.init({
	interpolation: { escapeValue: false },
	fallbackLng: "en",
	resources: {
		en: { global: en_translation },
		gr: { global: gr_translation },
		fr: { global: fr_translation },
	},
});

export default i18n;
