import queryString from "query-string";
import { randomASCIIString } from "../utils/string";

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

export const REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI;

export const SCOPES = [
	"https://www.googleapis.com/auth/userinfo.email",
	"https://www.googleapis.com/auth/userinfo.profile",
];

const stringifiedParams = queryString.stringify({
	client_id: GOOGLE_CLIENT_ID,
	redirect_uri: REDIRECT_URI,
	scope: SCOPES.join(" "),
	response_type: "code",
	access_type: "offline",
	prompt: "consent",
});

export const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;

const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_OAUTH_CLIENT_ID;

export const getDiscordLoginURL = () =>
	`https://discord.com/oauth2/authorize?response_type=code&client_id=${DISCORD_CLIENT_ID}&scope=identify email&state=${randomASCIIString(
		5,
	)}&redirect_uri=${REDIRECT_URI}&prompt=consent`;

export default {
	DISCORD_CLIENT_ID,
	GOOGLE_CLIENT_ID,
	REDIRECT_URI,
	GOOGLE_LOGIN_URL,
	getDiscordLoginURL,
};
