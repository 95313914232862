import { useUser } from "../../../../contexts/User/User";

export default () => {
	const { setIsLoginPopupOpen } = useUser();

	const handleCommentSubmit = () => {
		setIsLoginPopupOpen(true);
	};

	return {
		handleCommentSubmit,
	};
};
