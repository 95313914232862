export const getParts = str => {
	let matches = str.match(/(\*[^*]+\*)|([^*]+)/g);
	let response = [];

	matches.forEach(element => {
		let type, content;

		if (element.startsWith("*") && element.endsWith("*")) {
			type = "action";
			content = element.slice(1, -1).trim(); // Remove the asterisks and trim whitespace
		} else {
			type = "text";
			content = element.trim(); // Trim whitespace
		}

		response.push({ type, content });
	});

	return response;
};

export default {
	getParts,
};
