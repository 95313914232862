import { useMemo } from "react";

export default ({ date }) => {
	const timestamp = useMemo(
		$ => {
			if (!date) {
				return;
			}

			const hours = date.getHours().toString().padStart(2, "0");
			const minutes = date.getMinutes().toString().padStart(2, "0");
			return `${hours}:${minutes}`;
		},
		[date],
	);

	return { timestamp };
};
