import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, subTitle, title, description, CtaComponent, onClick }) => {
	return (
		<div
			className={classNamesModule(styles.container, ...(classNames || []))}
			style={{ ...style }}
			onClick={onClick}>
			<h2 className={styles.subTitle}>{subTitle}</h2>
			<h3 className={styles.title}>{title}</h3>
			<p className={styles.description}>{description}</p>
			{CtaComponent}
		</div>
	);
};
