import { useLocalStorage } from "usehooks-ts";
import { getLoginLink } from "../../utils/etc";
import { GOOGLE_LOGIN_URL, getDiscordLoginURL } from "../../constants/oauth";
import { randomASCIIString } from "../../utils/string";
import { useSearchParams } from "react-router-dom";
import { submitGtagEvent } from "../../utils/gtag";

export default () => {
	const [utmCampaign] = useLocalStorage("utmCampaign", null);
	const [ref] = useLocalStorage("ref", null);
	const [uref] = useLocalStorage("uref", null);

	const [authMethod, setAuthType] = useLocalStorage("authMethod", "telegram");

	const [searchParams] = useSearchParams();

	const handleTelegramLoginClick = () => {
		setAuthType("telegram");
		submitGtagEvent("login", {
			method: "Telegram",
		});
		window.location.href = getLoginLink({
			utmCampaign,
			ref,
			uref,
			isAdVersion: false,
			isChatAI: ["/chat-ai", "/chats", "/chat"].includes(window.location.pathname.split("?")[0]),
			chatAiGirlID: searchParams.get("girlid"),
			maloumUsername: searchParams.get("maloum_username"),
		});
	};

	const isChatsPath = window.location.pathname.split("?")[0] === "/chats";
	const isPublicGirlPath = searchParams.get("ispublicgirl") === "true";

	const oauthExtraParameter =
		"&state=yes=yes" +
		(isChatsPath ? `-chatai=true` : isPublicGirlPath ? `-chataigirlid=${searchParams.get("girlid")}` : "") +
		(ref ? `-ref=${ref}` : uref ? `-uref=${uref}` : "") +
		`${searchParams.get("maloum_username") ? `-maloumusername=${searchParams.get("maloum_username")}` : ""}`;

	const handleGoogleLoginClick = () => {
		submitGtagEvent("login", {
			method: "Google",
		});
		setAuthType("google");
		console.log({ googleloginurl: GOOGLE_LOGIN_URL + oauthExtraParameter });
		window.location.href = GOOGLE_LOGIN_URL + oauthExtraParameter;
	};

	const handleDiscordLoginClick = () => {
		submitGtagEvent("login", {
			method: "Discord",
		});
		window.location.href = getDiscordLoginURL() + oauthExtraParameter;
		setAuthType("discord");
	};

	return {
		handleTelegramLoginClick,
		handleGoogleLoginClick,
		handleDiscordLoginClick,
	};
};
