import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Item from "./components/Item/Item";
import { useUser } from "../../contexts/User/User";
import { getLoginLink } from "../../utils/etc";
import { useLocalStorage } from "usehooks-ts";
import { submitGtagEvent } from "../../utils/gtag";
import { msSinceDate, msToDays } from "../../utils/time";

export default () => {
	const [t] = useTranslation("global");

	const { user, setUser, setIsLoginPopupOpen } = useUser();

	const [loginPopupOpen, setLoginPopupOpen] = useState(false);

	const hasOpenTask = user && !user?.orders?.find(o => o.id === "JOIN");

	const [hasVisitedGenerate, setHasVisitedGenerate] = useLocalStorage("hasVisitedGenerate", false);
	const [hasVisitedChatAI, setHasVisitedChatAI] = useLocalStorage("hasVisitedChatAI", false);

	const location = useLocation();

	useEffect(
		$ => {
			if (location.pathname.includes("/generate")) {
				setHasVisitedGenerate(true);
			}

			if (location.pathname.includes("/chat")) {
				setHasVisitedChatAI(true);
			}
		},
		[location],
	);

	const isPaidUser = useMemo($ => user?.orders?.find(o => o?.dollarAmount > 0), [user]);

	const showGenerateFreeTrial = user?.coins < 18 && !hasVisitedGenerate && !isPaidUser;
	const showChatAIFreeTrial = user?.coins < 18 && !hasVisitedChatAI && !isPaidUser;

	const Items = useMemo(
		$ => {
			if (!user.isLoggedIn) {
				return (
					<>
						<Item
							onClick={$ => {
								setIsLoginPopupOpen(true);
							}}>
							{t("header.login")}
						</Item>
						<Item href="https://direct.lc.chat/15955239/" hrefTarget="_blank">
							{t("header.support")}
						</Item>
						<Item href="https://t.me/undressainews" hrefTarget="_blank">
							{t("header.news")}
						</Item>
						<Item href="/upload">{t("header.undress")}</Item>
						<Item
							href="/generate"
							onClick={e => {
								e.preventDefault();
								setLoginPopupOpen(true);
							}}
							sideText={!hasVisitedGenerate && "Try free"}>
							{t("header.generate")}
						</Item>
						<Item
							href="/earn"
							onClick={e => {
								e.preventDefault();
								setLoginPopupOpen(true);
							}}>
							{t("header.earn_coins")}
						</Item>
					</>
				);
			}

			return (
				<>
					<Item
						href="/recharge"
						onClick={$ => {
							submitGtagEvent("header_recharge_click");
						}}>
						{t("header.header_coins", {
							coins: user.coins?.toFixed(0),
						})}
					</Item>
					<Item
						href="/earn"
						sideText={hasOpenTask ? "1" : null}
						isSideTextCircle={true}
						onClick={$ => {
							submitGtagEvent("header_earn_click");
						}}>
						{t("header.earn_coins")}
					</Item>
					<Item
						href="https://t.me/undressainews"
						hrefTarget="_blank"
						onClick={$ => {
							submitGtagEvent("header_news_click");
						}}>
						{t("header.news")}
					</Item>
					<Item
						href="/upload"
						onClick={$ => {
							submitGtagEvent("header_undress_click");
						}}>
						{t("header.undress")}
					</Item>
					<Item
						href="/generate"
						onClick={$ => {
							submitGtagEvent("header_generate_click");
						}}
						sideText={showGenerateFreeTrial && "Try free"}>
						{t("header.generate")}
					</Item>
					<Item
						href="/chats"
						onClick={$ => {
							submitGtagEvent("header_chats_click");
						}}
						sideText={showChatAIFreeTrial && "Try free"}>
						{t("header.chat")}
					</Item>
				</>
			);
		},
		[user, hasOpenTask],
	);

	return {
		Items,
	};
};
