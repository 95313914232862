import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default () => {
	const [isLoginPopupShown, setIsLoginPopupShown] = useState(false);

	const handleLoginPopupClose = () => {
		setIsLoginPopupShown(false);
	};

	const handleCtaClick = () => {
		setIsLoginPopupShown(true);
	};

	const [searchParams] = useSearchParams();

	return { handleCtaClick, isLoginPopupShown, handleLoginPopupClose, searchParams };
};
