import Button from "../../../../components/Button/Button";
import styles from "./styles.module.css";
import useCta from "./useCta";

import { useTranslation } from "react-i18next";

export default ({ onClick, classNames, onSubmit, isLoading }) => {
	const [t] = useTranslation("global");
	const { handleInputChange } = useCta({ onSubmit });

	return (
		<Button
			onClick={onClick}
			classNames={[styles.container, ...(classNames || [])]}
			textClassNames={[styles.text]}
			isLoading={isLoading}>
			Select photo
			<input type="file" className={styles.input} onChange={handleInputChange} accept=".png,.jpg,.jpeg" />
		</Button>
	);
};
