import { Helmet } from "react-helmet";
import seo from "../../constants/seo";

export default function HeadSeo({
	title,
	description,
	canonical,
	ogLocale,
	ogDescription,
	ogTitle,
	ogImage,
	ogImageAlt,
	noIndex,
}) {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title || seo.defaultTitle}</title>
				{noIndex && <meta name="robots" content="noindex" />}
				<meta name="description" content={description || seo.defaultDescription} />
				{canonical && <link rel="canonical" href={canonical} />}
				<meta name="og:locale" content={ogLocale || seo.defaultOgLocale} />
				<meta name="og:title" content={ogTitle || seo.defaultTitle} />
				<meta name="og:description" content={ogDescription || seo.defaultDescription} />
				<meta name="og:site_name" content="Undressai.com" />
				<meta name="og:image" content={ogImage || seo.defaultOgImage} />
				<meta name="og:image:alt" content={ogImageAlt || seo.defaultOgImageAlt} />
				<meta name="twitter:title" content={title || seo.defaultTitle} />
				<meta name="twitter:description" content={ogDescription || seo.defaultDescription} />
				<meta property="twitter:image" content={ogImage || seo.defaultOgImage} />
				<meta property="twitter:card" content="summary_large_image" />
				<title>{title || seo.defaultTitle}</title>
			</Helmet>
		</>
	);
}
