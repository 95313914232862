import telegramBots from "../constants/telegramBots";
import { sample } from "./random";

export const getLoginLink = ({
	utmCampaign,
	ref,
	uref,
	isAdVersion,
	isChatAI,
	chatAiGirlID,
	telegramIDToAssign,
	maloumUsername,
}) => {
	const notificationBot = sample(telegramBots.notificationBots);

	return `https://t.me/${notificationBot}?start=action=login${utmCampaign ? `-utmCampaign=${utmCampaign}` : ``}${
		ref ? `-ref=${ref}` : ``
	}${uref ? `-uref=${uref}` : ``}${isAdVersion ? `-av=true` : ``}${
		window.location.hostname === "undressgpt.com" || window.location.port === "3002" ? "-isUGPT=true" : ""
	}-ape=true-chatai=${!!isChatAI}${chatAiGirlID ? `-chataigirlid=${chatAiGirlID}` : ""}${
		telegramIDToAssign ? `-telegramidtoassign=${telegramIDToAssign}` : ""
	}${maloumUsername ? `-maloumusername=${maloumUsername}` : ""}`;
};

export default {
	getLoginLink,
};
