import { useState } from "react";
import { sleep } from "../../utils/promises";
import axiosInstance from "../../axios/axiosInstance";
import { useUser } from "../../contexts/User/User";
import { useNavigate } from "react-router-dom";

export default ({ coinGift, user, setUser, onClose }) => {
	const [currentStage, setCurrentStage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const handleOpenClick = async () => {
		setIsLoading(true);

		if (currentStage === 2) {
			await axiosInstance
				.post("/etc/christmas/open-door", {
					dayOfMonth: new Date().getDate(),
				})
				.catch($ => $);
		}
		await sleep(1000);

		setIsLoading(false);
		setCurrentStage(before => before + 1);
	};

	const handleTopupClick = () => {
		navigate("/recharge");
		onClose();
	};

	const handleGreatClick = () => {
		onClose();
		setTimeout($ => {
			setUser(before => ({
				...before,
				coins: before.coins + coinGift,
			}));
		}, 2000);
	};

	return {
		currentStage,
		handleOpenClick,
		isLoading,
		handleTopupClick,
		handleGreatClick,
	};
};
