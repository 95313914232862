export const sample = arr => {
	return arr[Math.floor(Math.random() * arr.length)];
};

export const biasedSample = (arr, probabilities) => {
	if (arr.length !== probabilities.length) {
		throw new Error("Arrays must be of the same length");
	}

	const totalSum = probabilities.reduce((acc, val) => acc + val, 0);

	const rand = Math.random() * totalSum;

	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum += probabilities[i];
		if (rand < sum) {
			return arr[i];
		}
	}

	return null;
};

export default {
	sample,
	biasedSample,
};
