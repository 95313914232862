import { PRODUCTION } from "./general";

// export const notificationBots = process.env.REACT_APP_DNW_TELEGRAM_TEST_BOT
export const notificationBots = PRODUCTION
	? [
			"unotificationsbot",
			"umessagesbot",
			"u_notificationsbot",
			"u_messagesbot",
			"u_messages_bot",
			// "b1_unotificationsbot",
			"u_notifier_bot",
			"unotification_bot",
			"upusherbot",
	  ]
	: [process.env.REACT_APP_DNW_TELEGRAM_TEST_BOT];

export default {
	notificationBots,
};
