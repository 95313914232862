import { useSpringValue, animated } from "react-spring";
import styles from "./styles.module.css";
import { useEffect, useState, useMemo } from "react";
import useVoiceMessage from "./useVoiceMessage";
import _conStyles from "./conStyles";

export default ({ isAuthor, timestamp, duration, isBottom, isTop, src }) => {
	const {
		playOpacity,
		playScale,
		isPlaying,
		setIsPlaying,
		play,
		stop,
		pauseOpacity,
		pauseScale,
		waves,
		myOpacity,
		myHeight,
		myTransform,
		Audio,
		audioProgressPercent,
		handleWaveClick,
		currentDuration,
	} = useVoiceMessage({
		isAuthor,
		timestamp,
		duration,
		isBottom,
		isTop,
		src,
	});
	const conStyles = useMemo($ => _conStyles({ isAuthor }), [isAuthor]);
	return (
		<>
			{/* {FakeElement} */}
			<animated.div className={styles.container} style={conStyles.conVoiceMessage}>
				{Audio}
				<div className={styles.wrapper} style={conStyles.conWrapperDiv}>
					<div
						className={styles.playContainer}
						onClick={$ => {
							setIsPlaying(!isPlaying);
						}}
						style={conStyles.conPlayContainer}>
						<animated.div
							className={styles.playSvgContainer}
							style={{
								position: "absolute",
								opacity: playOpacity,
								transform: playScale.to(scale => `scale(${scale})`),
							}}>
							<svg
								width="60"
								height="66"
								viewBox="0 0 60 66"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className={styles.play}>
								<path
									d="M5.97607 65.3145C6.82239 65.3145 7.62805 65.168 8.39307 64.875C9.15808 64.582 9.94739 64.1914 10.7612 63.7031L54.7065 38.4102C56.4969 37.3685 57.7339 36.4489 58.4175 35.6514C59.1011 34.8539 59.4429 33.9017 59.4429 32.7949C59.4429 31.6881 59.1011 30.736 58.4175 29.9385C57.7339 29.141 56.4969 28.2213 54.7065 27.1797L10.7612 1.88672C9.94739 1.39844 9.15808 1.00781 8.39307 0.714844C7.62805 0.421875 6.82239 0.275391 5.97607 0.275391C4.44617 0.275391 3.23352 0.820679 2.33838 1.91113C1.44324 3.00159 0.995605 4.45837 0.995605 6.28125V59.3086C0.995605 61.1315 1.44324 62.5883 2.33838 63.6787C3.23352 64.7692 4.44617 65.3145 5.97607 65.3145Z"
									fill={isAuthor ? "#786CCC" : "white"}
								/>
							</svg>
						</animated.div>
						<animated.div
							className={styles.pauseSvgContainer}
							style={{
								position: "absolute",
								opacity: pauseOpacity,
								transform: pauseScale.to(scale => `scale(${scale})`),
							}}>
							<svg
								width="60"
								height="66"
								viewBox="0 0 60 66"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className={styles.play}>
								<path
									d="M22.5 63.9031V2.07969C22.5 0.928125 21.2344 0 19.6406 0H2.85937C1.26562 0 0 0.928125 0 2.07969V63.9031C0 65.0547 1.26562 66 2.85937 66H19.6406C21.2344 66 22.5 65.0719 22.5 63.9031Z"
									fill={isAuthor ? "#786ccc" : "white"}
								/>
								<path
									d="M57.1406 0H40.3594C38.7891 0 37.5 0.928125 37.5 2.07969V63.9031C37.5 65.0547 38.7656 66 40.3594 66H57.1406C58.7109 66 60 65.0719 60 63.9031V2.07969C60 0.928125 58.7344 0 57.1406 0Z"
									fill={isAuthor ? "#786ccc" : "white"}
								/>
							</svg>
						</animated.div>
					</div>
					<div className={styles.right}>
						<div className={styles.waves}>
							<svg
								width={waves.length * 3}
								height="22"
								viewBox={`0 0 ${waves.length * 3} 22`}
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								{waves.map((w, i) => {
									const isPlayed = (i / waves.length) * 100 <= audioProgressPercent;
									return (
										<rect
											key={i}
											x={i * 3}
											y={(22 - w) / 2}
											width="1"
											height={w}
											rx="0.5"
											fill={isAuthor ? "white" : "#786CCC"}
											opacity={isPlayed ? 1 : 0.5}
											style={conStyles.conRect}
											onClick={$ =>
												handleWaveClick({
													wavesIndex: i,
												})
											}
										/>
									);
								})}
							</svg>
						</div>
						<div className={styles.metadataContainer}>
							<span className={styles.duration}>{currentDuration}</span>
							<span className={styles.timestamp}>{timestamp}</span>
						</div>
					</div>
				</div>
				<img
					src={isAuthor ? "/assets/chat/penis.png" : "/assets/chat/penisGray.png"}
					className={styles.penis}
					style={conStyles.conPenis}
				/>
			</animated.div>
		</>
	);
};
