import formatting from "./formatting";
import promises from "./promises";
import time from "./time";
import string from "./string";
import events from "./events";
import undress from "./undress";
import etc from "./etc";
import gtag from "./gtag";
import { navJS } from "./navjs";
import styles from "./styles";
import colors from "./colors";

export default {
	formatting,
	promises,
	time,
	string,
	events,
	undress,
	etc,
	gtag,
	navJS,
	styles,
	colors,
};
