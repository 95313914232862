import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style }) => {
	const faqs = [
		{
			question: "What is an AI deepfake porn generator?",
			answer: "A deepfake AI porn generator is a tool that allows you to generate sexual porn images that look like a specific person. On our platform, you only have to upload one photo of a girl, and instantly start creating AI porn images.",
		},
		{
			question: "What AI Deepfake Porn photos can i generate?",
			answer: "On our platform, there are no limits on what AI porn images you can create. Simply upload a photo, and specify what you want to see. Wether you want her to be masturbating in a bathroom, having sex on the street, or kissing with a random stranger in a bus, it's your choice.",
		},
		{
			question: "How long does it take to create AI deepfake sex images?",
			answer: "The generation time of AI deepfake photos depends on many factors, but generally it only takes up to one minute to generate a picture.",
		},
		{
			question: "Do i need technical knowledge to use this AI porn faceswap creator?",
			answer: "No, you do not need any technical knowledge to use this tool. Our porn deepfake app is very user friendly and easy to understand. ",
		},
		{
			question: "Are my images private?",
			answer: "All your created images are completely private and strongly encrypted. They will never be shared with anyone.",
		},
		{
			question: "Are there no restrictions to using this face swap porn tool?",
			answer: "Our platform strictly prohibits the use of this tool for any malicious purposes, such as blackmail or harassment. We have measures in place to prevent such misuse and take it very seriously.",
		},
		{
			question: "Are the AI deepfake porn images realistic?",
			answer: "With the advancement of AI technology, the generated deepfake porn images can be highly realistic. However, it ultimately depends on the quality of the photo provided and the specifications given for the desired image.",
		},
		{
			question: "Can I upload any photo to generate AI porn images?",
			answer: "Our platform only allows users to upload photos of people who are 18 years or older. We also have strict guidelines against uploading photos of celebrities or public figures without their consent.",
		},
		{
			question: "Is this deepfake faceswap AI tool legal?",
			answer: "The legality of AI deepfake porn is a controversial topic. Some countries have laws against it, while others do not. We advise users to use this tool responsibly and to ensure that the person in the photo has given their consent.",
		},
		{
			question: "Can I request specific features for this porn faceswapper AI?",
			answer: "We are constantly working to improve our AI deepfake porn generator and welcome any feedback or suggestions for features! You can contact our team via email support@undressai.com.",
		},
		{
			question: "Is there a limit on how many AI deepfake porn images I can create?",
			answer: "There is no limit on how many AI porn images you can create with our deepfake service.",
		},
	];

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<h2 className={styles.title}>Frequently asked questions</h2>
			{faqs.map(({ question, answer }) => (
				<>
					<h3 className={styles.question}>{question}</h3>
					<p className={styles.answer}>{answer}</p>
				</>
			))}
		</div>
	);
};
