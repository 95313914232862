import { animated } from "react-spring";
import LookingAtPhoto from "./components/LookingAtPhoto/LookingAtPhoto";
import RecordingVoice from "./components/RecordingVoice/RecordingVoice";
import TakingPhoto from "./components/TakingPhoto/TakingPhoto";
import Typing from "./components/Typing/Typing";
import "./styles.css";
import styles from "./styles.module.css";
import useTextMessage from "./useTextMessage";
import classNamesModule from "classnames";

export default ({
	isAuthor,
	text,
	date,
	isBottom,
	isTop,
	style,
	isAction,
	isTyping,
	isTakingPhoto,
	isLookingAtPhoto,
	isRecordingVoice,
	classNames,
	textClassNames,
}) => {
	const { FakeElement, myTextOpacity, timestamp } = useTextMessage({
		isAuthor,
		isBottom,
		isTop,
		text,
		style,
		isAction,
		isTyping,
		date,
	});

	return (
		<>
			{FakeElement}
			<animated.div
				className={classNamesModule(styles.container, ...(classNames || []))}
				style={{
					flexDirection: isAuthor ? "row" : "row-reverse",
					alignSelf: isAuthor ? "flex-end" : "flex-start",
					marginBottom: isBottom ? 0 : "0.25rem",
					...style,
				}}>
				<animated.div
					className={styles.wrapper}
					style={{
						borderBottomRightRadius:
							isAuthor && isBottom ? 0 : isAuthor && !isBottom ? "0.375rem" : "0.75rem",
						borderBottomLeftRadius:
							!isAuthor && isBottom ? 0 : !isAuthor && !isBottom ? "0.375rem" : "0.75rem",
						borderTopRightRadius: isTop || !isAuthor ? "0.75rem" : "0.375rem",
						borderTopLeftRadius: isTop || isAuthor ? "0.75rem" : "0.375rem",
						background: isAuthor
							? "linear-gradient(to left, #FF0000, #E00051)"
							: "linear-gradient(to right, #7000FF, #8E11BC)",
						animation:
							isTakingPhoto || isRecordingVoice || isLookingAtPhoto
								? "shine 1s infinite alternate ease-in"
								: undefined,
					}}>
					<animated.span className={styles.text}>
						{isTyping ? (
							<Typing />
						) : isTakingPhoto ? (
							<TakingPhoto />
						) : isLookingAtPhoto ? (
							<LookingAtPhoto />
						) : isRecordingVoice ? (
							<RecordingVoice />
						) : (
							<animated.div
								style={{ opacity: myTextOpacity }}
								className={classNamesModule(styles.messageText, ...(textClassNames || []))}>
								{text}
							</animated.div>
						)}
					</animated.span>
					<animated.span className={styles.timestamp} style={{ opacity: myTextOpacity }}>
						{!isTyping && !isTakingPhoto && !isLookingAtPhoto && timestamp}
					</animated.span>
				</animated.div>
				{/* {
					<img
						src={isAuthor ? "/assets/chat/penis.png" : "/assets/chat/penisGray.png"}
						className={styles.penis}
						style={{
							transform: isAuthor
								? "scale(1) scale(1) translateX(-0.5px) translateY(0.8px)"
								: "scaleX(-1) scale(1) translateX(-0.5px) translateY(0.8px)",
							opacity: isBottom ? 1 : 0,
						}}
					/>
				} */}
			</animated.div>
		</>
	);
};
