import Link from "../components/Link/Link";
import { submitGtagEvent } from "../utils/gtag";

export const undress = [
	{
		src: "/assets/undressGuide/tip1.jpg",
		text: "You can give any pose a try, but a full standing pose can significantly enhance undressing results. If you're not satisfied, don't hesitate to switch things up!",
	},
	{
		src: "/assets/undressGuide/tip2.jpg",
		text: "Ensure the clothing to be removed is fully painted, including folds and the like. When in doubt, give the editor more rather than less space for optimal results!",
	},
	// {
	// 	src: "/assets/undressGuide/tip3.jpg",
	// 	text: "Did you get a good result with minor blemishes? Don't worry! Just upload it again and repaint the problem areas for the perfect finish.",
	// },
	// {
	// 	src: "/assets/undressGuide/tip4.jpg",
	// 	text: "If parts of the clothing still remain, paint a bigger outline. If you're still unsuccessful, switch pictures. Repeated attempts on the same picture won't yield different results.",
	// },
];

export const pay = [
	{
		src: "/assets/payGuide/1.png",
		text: (
			<>
				1️⃣ Go to{" "}
				<Link
					href="https://4Based.com/profile/mr.undress"
					target="_blank"
					onClick={$ => {
						submitGtagEvent("fourbased_payment_guide_mrundress_click");
					}}>
					4based.com/profile/mr.undress
				</Link>{" "}
				and click on message
			</>
		),
	},
	{
		src: "/assets/payGuide/2.png",
		text: "2️⃣ Click on the dollar sign button",
	},
	{
		src: "/assets/payGuide/3.png",
		text: "3️⃣ Click on deposit",
	},
	{
		src: "/assets/payGuide/4.png",
		text: "4️⃣ Buy enough 4Based-credits to pay the amount stated on undressai + taxes",
	},
	{
		src: "/assets/payGuide/5.png",
		text: "5️⃣ Click on the dollar sign button again",
	},
	{
		src: "/assets/payGuide/6.png",
		text: "6️⃣ Enter the amount to send as stated on undressai",
	},
	{
		src: "/assets/payGuide/7.png",
		text: "7️⃣ Click on send",
	},
	{
		src: "/assets/payGuide/8.png",
		text: "8️⃣ Enter your 4Based username on the bottom of the undress payment page (On which you are on right now)",
	},
	{
		src: "/assets/payGuide/9.png",
		text: "9️⃣ You receive your coins within 30 seconds and can start undressing 😍",
	},
];

export default { undress, pay };
