import general from "./general.js";
import api from "./api.js";
import objects from "./objects.js";
import events from "./events.js";
import telegramBots from "./telegramBots.js";
import guides from "./guides.js";
import unicode from "./unicode.js";
import tasks from "./tasks.js";
import etc from "./etc.js";
import oxe from "./oxe.js";
import googleanalytics from "./googleanalytics.js";
import oauth from "./oauth.js";
import seo from "./seo.js";

export default {
	general,
	api,
	objects,
	events,
	telegramBots,
	guides,
	unicode,
	tasks,
	etc,
	oxe,
	googleanalytics,
	oauth,
	seo,
};
