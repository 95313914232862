import styles from "./styles.module.css";
import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

export default ({ classNames }) => {
	const [t] = useTranslation("global");

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))}>
			<div className={styles.blink} />
			<div className={styles.text}>{t("chatHistory.opens_photo")}</div>
		</div>
	);
};
