import Footer from "../../components/Footer/Footer";
import Link from "../../components/Link/Link";
import Card from "./components/Card/Card";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<Link href="/">
				<img className={styles.logo} src="/assets/undresslanding/logo.webp" alt="undress ai logo" />
			</Link>
			<div className={styles.cards}>
				<Card
					title="Best Deepfake Nude Undressing And Nudifying AI"
					imgSrc="/assets/random/otherplatforms.png"
					href="/blog/top-undress-ai-apps"
				/>
			</div>
			<Footer />
		</div>
	);
};
