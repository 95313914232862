export const getIsChatAI = () => {
	console.log({ windowLocationHref: window.location.href });

	const isChatAI =
		window.location.href.includes("destination=chat") ||
		window.location.href.includes("/chat/") ||
		["/chat", "/chats"].includes(window.location.pathname);

	return isChatAI;
};

export const getIsGenerate = () => {
	const isGenerate =
		window.location.href.includes("destination=customize") ||
		["/generate", "/generate/customize", "/generate/result", "/generate/loading"].includes(
			window.location.pathname,
		);

	return isGenerate;
};

export const onboardingCorner = () => ({
	background: getIsChatAI()
		? "linear-gradient(to right, #1E1E1E, #1E1E1E), linear-gradient(to bottom, #9E00FF, #FF1523)"
		: getIsGenerate()
		? "linear-gradient(to right, #1E1E1E, #1E1E1E), linear-gradient(to bottom, #5200FF, #AD00FF)"
		: "linear-gradient(to right, #1E1E1E, #1E1E1E), linear-gradient(to bottom, #6E49FF, #6E49FF)",
	border: "4px solid transparent",
	borderRadius: ".5rem",
	backgroundClip: "padding-box, border-box",
	backgroundOrigin: "padding-box, border-box",
});

export const onboardingInner = () => ({
	background: getIsChatAI()
		? "linear-gradient(to bottom, #9E00FF, #FF1523)"
		: getIsGenerate()
		? "linear-gradient(to bottom, #5200FF, #AD00FF)"
		: "#6E49FF",
	borderRadius: ".5rem",
});

// box-shadow: 0px 0px 2px 2px rgba(110, 73, 255, 0.66) !important;
export const onboardingShadow = () => ({
	boxShadow: getIsChatAI()
		? "0px 0px 2px 2px rgba(255, 21, 35, 0.4)"
		: getIsGenerate()
		? "0px 0px 2px 2px rgba(173, 0, 255, 0.4)"
		: "",
});

export default {
	onboardingCorner,
	onboardingInner,
	onboardingShadow,
};
