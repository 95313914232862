import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { submitGtagEvent } from "../../../../utils/gtag";

export default () => {
	const isSeoLandingPage = window.location.pathname === "/chat-ai";

	const [origin, setOrigin] = useLocalStorage("origin", null);
	const [ttoken, setTtoken] = useLocalStorage("ttoken", null);
	const [oauthRefreshToken, setOauthRefreshToken] = useLocalStorage("oauthRefreshToken", null);
	const [ref, setRef] = useLocalStorage("ref", null);
	const [isAdVersion, setIsAdVersion] = useLocalStorage("isAdVersion", null);

	const [showUserAgreementPopup, setShowUserAgreementPopup] = useState(false);

	const handleUserAgreementPopupClose = () => {
		setShowUserAgreementPopup(false);
	};

	const cards = [
		{
			emoji: "😇",
			title: "Easy To Use",
			description: "Just upload photos of your crush and start chatting. It’s that easy.",
		},
		{
			emoji: "😜",
			title: "Unrestricted AI",
			description: "Chat with your AI crush about anything. Without restrictions :)",
		},
		{
			emoji: <img style={{ height: "3.5rem", width: "auto" }} src="/assets/undresslanding/verified.png" />,
			title: "It’s completely safe",
			description: "Everything is encrypted and anonymous.",
		},
	];

	const [faceFilesBase64, setFaceFilesBase64] = useLocalStorage("faceFilesBase64", []);

	function getBase64(file, onLoadCallback) {
		return new Promise(function (resolve, reject) {
			var reader = new FileReader();
			reader.onload = function () {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	const handleFaceFilesChange = e => {
		// submitGtagEvent("landingpage_cta_click", {
		// 	position: googlePrefix,
		// 	isLoggedIn: !!ttoken,
		// });

		e.preventDefault();

		if (ttoken || oauthRefreshToken) {
			setFaceFilesBase64([]);

			const filesArray = Array.from(e.target.files);

			for (const fileBase64 of filesArray.slice(0, 5)) {
				getBase64(fileBase64).then(base64result => setFaceFilesBase64(before => [...before, base64result]));
			}

			const path = "/new/getname?destination=chat&has_ls_photos=true";

			if (isSeoLandingPage) {
				window.location.href = path;
			} else {
				navigate(path);
			}
			return;
		}

		setShowUserAgreementPopup(true);
	};

	const navigate = useNavigate();

	const handleFilesSubmit = () => {};

	useEffect($ => {
		setFaceFilesBase64([]);
	}, []);

	const [showLoginPopup, setShowLoginPopup] = useState(false);

	const handleLoginPopupClose = () => {
		setShowLoginPopup(false);
	};

	const handleLoginTextClick = e => {
		e.preventDefault();

		if (ttoken || oauthRefreshToken) {
			submitGtagEvent("login_landing_already_logged_in");
			window.location.href = "/chats";
			return;
		}

		submitGtagEvent("login_landing_not_logged_in");

		setShowUserAgreementPopup(true);
	};

	const heroRef = useRef();

	const handleCtaClick = () => {
		window.location.href = "/new/getphoto?destination=chat";
	};

	return {
		cards,
		handleFaceFilesChange,
		handleFilesSubmit,
		faceFilesBase64,
		isSeoLandingPage,
		handleLoginTextClick,
		showLoginPopup,
		handleLoginPopupClose,
		showUserAgreementPopup,
		handleUserAgreementPopupClose,
		heroRef,
		handleCtaClick,
	};
};
