export const VAST_URL = "https://s.magsrv.com/splash.php?idzone=5101142";

export const INSTANT_MESSAGE_ZONE_ID = "5101144";

export const VIDEOSLIDER_ZONE_ID = "5101262";

export const DESKTOP_POPUNDER_ZONE_ID = "5101330";

export const DESKTOP_BANNER_ZONE_ID = "5101332";

export const DESKTOP_FULLPAGE_ZONE_ID = "5101334";

export const MOBILE_BANNER_ZONE_ID = "5101336";

export const MOBILE_POPUNDER_ZONE_ID = "5101340";

export const MOBILE_INSTANT_MESSAGE_ZONE_ID = "5101344";

export const MOBILE_FULLPAGE_ZONE_ID = "5101346";

export const NATIVE_WIDGET_ZONE_ID = "5101348";

export default {
	VAST_URL,
	INSTANT_MESSAGE_ZONE_ID,
	VIDEOSLIDER_ZONE_ID,
	DESKTOP_POPUNDER_ZONE_ID,
	DESKTOP_BANNER_ZONE_ID,
	DESKTOP_FULLPAGE_ZONE_ID,
	MOBILE_BANNER_ZONE_ID,
	MOBILE_POPUNDER_ZONE_ID,
	MOBILE_INSTANT_MESSAGE_ZONE_ID,
	MOBILE_FULLPAGE_ZONE_ID,
	NATIVE_WIDGET_ZONE_ID,
};
