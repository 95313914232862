import axiosInstance from "../axios/axiosInstance";
import { randomASCIIString } from "./string";
import lzstring from "lz-string";

export const sam = async ({ fileToSam, positivePrompt, expandBy = 0 }) => {
	const formData = new FormData();

	formData.append("file", fileToSam);
	formData.append("positivePrompt", positivePrompt);
	formData.append("expandBy", expandBy);

	let res;
	try {
		res = await axiosInstance.post("/ai/sam0503", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
	} catch (err) {
		console.log("error in sam", err);
		res = {
			data: {
				error: "some error",
			},
		};
	}

	const { drawingObject, hasSamCooldown, error } = res.data;

	return { drawingObject, hasSamCooldown, error };
};

export const undress = async ({
	fileToUndress,
	drawingObjectString,
	positivePrompt,
	negativePrompt,
	paidQueueItemID,
}) => {
	const formData = new FormData();

	formData.append("files", fileToUndress);
	formData.append("positivePrompt", positivePrompt);
	formData.append("negativePrompt", negativePrompt);
	formData.append("paidQueueItemID", paidQueueItemID);

	const compressedDrawingObjectString = lzstring.compressToBase64(drawingObjectString);
	const drawingObjectFile = new Blob([compressedDrawingObjectString], { type: "text/plain" });

	formData.append("files", drawingObjectFile, `${randomASCIIString(10)}.txt`);

	let res;

	try {
		res = await axiosInstance.post("/ai/undress0503", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
	} catch (err) {
		console.log("error in undress", err);
		res = {
			data: {
				error: "some error",
			},
		};
	}

	const { error, filenames, paidQueueID, s3FilepathPhotoToUndress, isAlreadyUndressing, existingQueueItemID } =
		res.data;

	return { error, filenames, paidQueueID, s3FilepathPhotoToUndress, isAlreadyUndressing, existingQueueItemID };
};

export const freeUndress = async ({ fileToUndress }) => {
	const formData = new FormData();

	formData.append("file", fileToUndress);

	let res;

	try {
		res = await axiosInstance.post("/ai/undress-free", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
	} catch (err) {
		console.log("error in undress", err);
		res = {
			data: {
				error: "some error",
			},
		};
	}

	const { error, filepath } = res.data;

	return { error, filepath };
};

export default {
	sam,
	undress,
	freeUndress,
};
