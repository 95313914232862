import { Flipped, Flipper } from "react-flip-toolkit";
import styles from "./styles.module.css";
import useComment from "./useComment";

export default ({ commentsObj }) => {
	const { handleCommentSubmit } = useComment();

	return (
		<Flipper flipKey={commentsObj?.map(comment => comment.text).join("")}>
			<div className={styles.selfCommentContainer}>
				<input placeholder="Your comment.." className={styles.selfCommentInput} />
				<button className={styles.commentButton} onClick={handleCommentSubmit}>
					Send
				</button>
			</div>
			<section className={styles.testimonialsContainer}>
				{commentsObj.map((comment, i, arr) => (
					<Flipped flipId={comment.text} key={comment.text}>
						<div className={styles.commentContainer} style={{ marginBottom: "1rem" }}>
							<div className={styles.textContainer}>
								<div className={styles.textHeading}>
									<h2 className={styles.name}>{comment.author}</h2>
									<div className={styles.time}>{comment.time}</div>
								</div>
								<p className={styles.text}>{comment.text}</p>
							</div>
						</div>
					</Flipped>
				))}
			</section>
		</Flipper>
	);
};
