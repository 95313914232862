import general from "./general";

export const API_SERVER = process.env.REACT_APP_API_ENDPOINT;

export const SECOND_API_SERVER = process.env.REACT_APP_API_ENDPOINT2;

export default {
	API_SERVER,
	SECOND_API_SERVER,
};
