import classNamesModule from "classnames";
import { animated } from "react-spring";
import utils from "../../utils";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./styles.module.css";
import useButton from "./useButton";

export default ({
	children,
	isDeactivated,
	classNames,
	onClick,
	style,
	textStyle,
	textClassNames,
	isLoading,
	isShiny,
	loadingText,
	isSecondary,
	secondaryColor,
}) => {
	const { handleClick, conStyles, springProps } = useButton({ isDeactivated, onClick, isSecondary, secondaryColor });

	return (
		<animated.a
			className={classNamesModule("link", styles.container, isShiny ? styles.shiny : "", ...(classNames || []))}
			style={{ ...conStyles.container, ...springProps, ...style }}
			onClick={handleClick}>
			<span className={classNamesModule(styles.text, ...(textClassNames || []))} style={textStyle}>
				{isLoading && loadingText ? loadingText : children}
			</span>
			{isLoading && <LoadingSpinner />}
		</animated.a>
	);
};
