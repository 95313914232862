import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/Button/Button";
import styles from "./styles.module.css";
import useExampleCTA from "./useExampleCTA";

export default ({ style, classNames, onClick, onSubmit }) => {
	const { handleInputChange } = useExampleCTA({ onSubmit });
	const [t] = useTranslation("global");

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={style}>
			<Button classNames={[styles.image, styles.button]} onClick={onClick}>
				{/* <input type="file" className={styles.input} onChange={handleInputChange} /> */}
				<span className={styles.buttonText}>
					<span className={styles.highlight}>*Click*</span> to undress own
				</span>
			</Button>
			<div className={styles.arrow}>➡️</div>
			<div className={classNamesModule(styles.image, styles.button, styles.buttonAfter)} />
		</div>
	);
};
