import { useEffect, useRef, useState } from "react";
import { easings, useSpringValue } from "react-spring";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import string from "../../../../utils/string";

export default ({ isAuthor, isBottom, isTop, duration, timestamp, src }) => {
	const [isPlaying, setIsPlaying] = useState(false);

	const playOpacity = useSpringValue(1);
	const playScale = useSpringValue(1, {
		config: {
			tension: 300,
			friction: 10,
		},
	});

	const pauseOpacity = useSpringValue(0);
	const pauseScale = useSpringValue(0.5, {
		config: {
			tension: 300,
			friction: 10,
		},
	});

	useEffect(() => {
		if (isPlaying) {
			playOpacity.start(0, { immediate: true });
			playScale.start(0.5);
			pauseOpacity.start(1, { immediate: true });
			pauseScale.start(1);
		} else {
			pauseOpacity.start(0, { immediate: true });
			pauseScale.start(0.5);
			playOpacity.start(1, { immediate: true });
			playScale.start(1);
		}
	}, [isPlaying]);

	const waves = [2, 5, 10, 12, 2, 2, 2, 5, 10, 10, 10, 5, 5, 20, 18, 15, 2, 2, 2, 10, 12, 10, 2, 2, 2, 5, 10, 12, 2];

	// animation stuff

	const myRef = useRef();

	const [hasAnimated, setHasAnimated] = useState(false);

	const audioRef = useRef(null);

	useEffect(() => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.play();
			} else {
				audioRef.current.pause();
			}
		}
	}, [isPlaying]);

	const [audioProgressPercent, setAudioProgressPercent] = useState(0);

	const handleTimeUpdate = () => {
		if (!audioRef.current) {
			return;
		}

		const percentage = (audioRef.current.currentTime / audioRef.current.duration) * 100;
		setAudioProgressPercent(percentage);
	};

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
		}

		return () => {
			if (audioRef.current) {
				audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
			}
		};
	}, []);

	const handleWaveClick = ({ wavesIndex }) => {
		if (audioRef.current) {
			audioRef.current.currentTime = (wavesIndex / waves.length) * audioRef.current.duration;
			updateCurrentDuration();
		}
	};

	const handleEnded = () => {
		setIsPlaying(false);
	};

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.addEventListener("ended", handleEnded);
		}

		return () => {
			if (audioRef.current) {
				audioRef.current.removeEventListener("ended", handleEnded);
			}
		};
	}, []);

	const [currentDuration, setCurrentDuration] = useState(duration);
	const [intervalId, setIntervalId] = useState(null);

	const updateCurrentDuration = () => {
		if (!audioRef.current) {
			return;
		}

		const seconds = Math.floor(audioRef.current.currentTime % 60);

		setCurrentDuration(string.getCounter(seconds));
	};

	const location = useLocation();

	useEffect(() => {
		if (isPlaying && location.pathname === "/chat") {
			updateCurrentDuration();
			const id = setInterval(updateCurrentDuration, 1000);

			setIntervalId(id);
		} else {
			clearInterval(intervalId);
		}
		return () => {
			clearInterval(intervalId);
		};
	}, [isPlaying, location]);

	return {
		isPlaying,
		setIsPlaying,
		playOpacity,
		playScale,
		pauseOpacity,
		pauseScale,
		waves,
		Audio: <audio ref={audioRef} src={src} />,
		audioProgressPercent,
		handleWaveClick,
		currentDuration,
	};
};
