import classNamesModule from "classnames";
import Button from "../../../../components/Button/Button";
import styles from "./styles.module.css";
import useSelectPhoto from "./useSelectPhoto";

export default ({
	classNames,
	setSelectedUndressPhoto,
	title,
	description,
	style,
	handleChooseOwnImage,
	uploadButtonText,
}) => {
	const { isUploadButtonLoading } = useSelectPhoto({ setSelectedUndressPhoto });

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<input className={styles.input} type="file" onChange={handleChooseOwnImage} accept=".png,.jpg,.jpeg" />
			{/* <img src="/assets/random/upload.png" className={styles.uploadImg} /> */}
			<div className={styles.examplesContainer}>
				<img src="/assets/funnel/beforeexample.png" className={styles.exampleImg} />
				<div className={styles.rightArrows}>⏩⏩</div>
				<img src="/assets/funnel/afterexample.png" className={styles.exampleImg} />
			</div>
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description || "Files supported .jpg, .png"}</div>
			<Button classNames={[styles.button]} isLoading={isUploadButtonLoading}>
				{uploadButtonText}
			</Button>
		</div>
	);
};
