import { useTranslation } from "react-i18next";

import Link from "../../../Link/Link";
import styles from "./styles.module.css";

export default ({ children, sideText, href, hrefTarget, onClick, classNames, unreadCount, isSideTextCircle }) => {
	const [t] = useTranslation("global");

	return (
		<Link classNames={[styles.container, ...(classNames || [])]} href={href} target={hrefTarget} onClick={onClick}>
			<span className={styles.text}>{children}</span>
			{sideText && (
				<div
					className={styles.newLabel}
					style={isSideTextCircle ? { height: "1rem", width: "1rem", padding: "0" } : {}}>
					{sideText}
				</div>
			)}
			{unreadCount > 0 && <div className={styles.unreadCount}>{unreadCount}</div>}
		</Link>
	);
};
