"use client";

import React from "react";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { PhotoProvider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route } from "react-router-dom";

// import { UserProvider } from "./contexts/User/User";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Homepage from "./routes/Homepage/Homepage";
import Error from "./routes/Error/Error";
import Blog from "./routes/Blog/Blog";
import SlideRoutes from "react-slide-routes";
import TopDeepnudeApps from "./routes/Article/articles/TopDeepnudeApps/TopDeepnudeApps";
import Article from "./routes/Article/Article";
import ChatAILandingPage from "./routes/Chats/components/LandingPage/LandingPage";
import GenerateLandingPage from "./routes/Generate/components/LandingPage/LandingPage";
import FreeUndressResult from "./routes/FreeUndressResult/FreeUndressResult";
import Funnel from "./routes/Funnel/Funnel";

const UserProvider = React.lazy(() => import("./contexts/User/User"));
const Onboarding = React.lazy(() => import("./routes/Onboarding/Onboarding"));
const SocketProvider = React.lazy(() => import("./contexts/Socket/Socket"));
const UndressStuff = React.lazy(() => import("./routes/UndressStuff/UndressStuff"));
const AntiAdblockProvider = React.lazy(() => import("./contexts/AntiAdblock/AntiAdblock"));

const chatPaths = [
	"/new/getname",
	"/new/getage",
	"/new/getphoto",
	"/new/gethair",
	"/new/gethaircolor",
	"/new/getbody",
	"/new/geteth",
	"/new/getaccent",
	"/new/getlanguage",
	"/new/getinterests",
	"/new/getusername",
	"/new/goal",
	"/new/loading",
	"/chat",
	"/chat/getaccent",
	"/chat/getlanguage",
	"/chat/getusername",
	"/chats",
	"/generate",
	"/generate/loading",
	"/generate/result",
	"/generate/customize",
];
const showChatRoutes = chatPaths.find(path => path === window.location.pathname);

const root = createRoot(document.getElementById("root"));
root.render(
	<>
		<PhotoProvider>
			<BrowserRouter>
				{[
					"/",
					"/blog",
					"/blog/top-undress-ai-apps",
					"/chat-ai",
					"/deepfake-porn-generator",
					"/free-undress-result",
				].includes(window.location.pathname) ? (
					<SlideRoutes>
						<Route path="/" element={<Homepage />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/chat-ai" element={<ChatAILandingPage />} />
						<Route path="/deepfake-porn-generator" element={<GenerateLandingPage />} />
						<Route
							path="/blog/top-undress-ai-apps"
							element={
								<Article>
									<TopDeepnudeApps
										seoTitle="Best Deepfake Nude Undressing And Nudifying AI"
										seoDescription="There are many undress AI apps out there. We will show you the best deepnude ai apps, so you can remove clothes from any photo."
									/>
								</Article>
							}
						/>
						<Route path="/free-undress-result" element={<FreeUndressResult />} />
					</SlideRoutes>
				) : ["/funnel", "/family-funnel", "/funnel/undressing", "/funnel/result"].includes(
						window.location.pathname,
				  ) ? (
					<UserProvider>
						<Funnel />
					</UserProvider>
				) : (
					<ErrorBoundary FallbackComponent={Error} onReset={() => (location.href = "/")}>
						<UserProvider>
							<AntiAdblockProvider>
								<ToastContainer />
								{showChatRoutes ? (
									<SocketProvider>
										<Onboarding />
									</SocketProvider>
								) : (
									<UndressStuff />
								)}
							</AntiAdblockProvider>
						</UserProvider>
					</ErrorBoundary>
				)}
			</BrowserRouter>
		</PhotoProvider>
	</>,
);

serviceWorkerRegistration.register();
