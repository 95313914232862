import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import Button from "../Button/Button";
import useLoginButtons from "./useLoginButtons";

export default ({ isOpen, closeOnOutsideClick, onClose, onLogin, classNames }) => {
	const { handleTelegramLoginClick, handleGoogleLoginClick, handleDiscordLoginClick } = useLoginButtons();

	const [t] = useTranslation("global");

	return (
		<div
			className={classNamesModule(styles.container, ...(classNames || []))}
			isOpen={isOpen}
			closeOnOutsideClick={closeOnOutsideClick}
			onClose={onClose}>
			<Button
				classNames={[styles.loginButton, styles.telegramLoginButton]}
				textClassNames={[styles.telegramButtonText]}
				onClick={handleTelegramLoginClick}>
				<img className={styles.telegramLogo} src="/assets/random/telegramLogoWhite.png" />
				<a className={styles.loginText}>Login with Telegram</a>
			</Button>
			<div className={styles.smallButtonContainer}>
				<Button
					classNames={[styles.loginButton, styles.smallLoginButton]}
					textClassNames={[styles.telegramButtonText]}
					onClick={handleGoogleLoginClick}>
					<img className={styles.googleLogo} src="/assets/random/googleLogo.png" />
					<a className={styles.loginText}>Google</a>
				</Button>
				<Button
					classNames={[styles.loginButton, styles.smallLoginButton]}
					textClassNames={[styles.telegramButtonText]}
					onClick={handleDiscordLoginClick}>
					<img className={styles.discordLogo} src="/assets/random/discordLogo.png" />
					<a className={styles.loginText}>Discord</a>
				</Button>
			</div>
		</div>
	);
};
