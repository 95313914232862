export const getWhiterHex = (hexColor, percentage) => {
	// Ensure percentage is between 0 and 100
	percentage = Math.max(0, Math.min(100, percentage)) / 100;

	// Convert hex to RGB
	let r = parseInt(hexColor.slice(0, 2), 16);
	let g = parseInt(hexColor.slice(2, 4), 16);
	let b = parseInt(hexColor.slice(4, 6), 16);

	// Calculate the new color components
	r += Math.round((255 - r) * percentage);
	g += Math.round((255 - g) * percentage);
	b += Math.round((255 - b) * percentage);

	// Convert RGB back to hex
	let newHex = [r, g, b]
		.map(x => {
			const hex = x.toString(16);
			return hex.length === 1 ? "0" + hex : hex;
		})
		.join("");

	return newHex.toUpperCase();
};

export default {
	getWhiterHex,
};
