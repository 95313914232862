import Button from "../../../../components/Button/Button";
import ChatHistory from "../../../../components/ChatHistory/ChatHistory";
import ImageMessage from "../../../../components/ChatHistory/components/ImageMessage/ImageMessage";
import TextMessage from "../../../../components/ChatHistory/components/TextMessage/TextMessage";
import { authors } from "../../../../constants/events";
import Card from "./components/Card/Card";
import FullCta from "./components/FullCta/FullCta";
import styles from "./styles.module.css";
import classNamesModule from "classnames";
import useLandingPage from "./useLandingPage";
import FAQ from "./components/FAQ/FAQ";
import { UNBREAKABLE_SPACE } from "../../../../constants/unicode";
import { Helmet } from "react-helmet";
import Link from "../../../../components/Link/Link";
import UserAgreementPopup from "../../../Upload/components/UserAgreementPopup/UserAgreementPopup";

export default ({ classNames, style }) => {
	const {
		cards,
		handleFaceFilesChange,
		faceFilesBase64,
		handleFilesSubmit,
		isSeoLandingPage,
		handleLoginTextClick,
		handleLoginPopupClose,
		showLoginPopup,
		showUserAgreementPopup,
		handleUserAgreementPopupClose,
		heroRef,
		handleCtaClick,
	} = useLandingPage();

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }} ref={heroRef}>
			<Helmet>
				<link rel="canonical" href="https://undressai.com/chat-ai" />
			</Helmet>
			{isSeoLandingPage && (
				<Link href="/chats" classNames={[styles.loginText]} onClick={handleLoginTextClick}>
					Login
				</Link>
			)}
			<div
				className={styles.hero}
				id="hero"
				style={{
					height:
						window.location.pathname === "/chats"
							? "calc(100vh - 3.5 * 2rem - 1rem - 0.5rem - 1rem)"
							: "100vh",
				}}>
				<h1 className={styles.title}>
					Turn every girl into a slutty <span className={styles.titleHighlight}>sexting AI girlfriend</span>
					{UNBREAKABLE_SPACE}😜
				</h1>
				<FullCta
					classNames={[styles.fullCta]}
					onFileChange={handleFaceFilesChange}
					isReady={faceFilesBase64.length}
					onSubmit={handleFilesSubmit}
				/>
				<img className={styles.downArrows} src="/assets/random/downArrows.png" />
			</div>
			<h2 className={classNamesModule(styles.title, styles.secondTitle)}>😏 Realistic chat experience</h2>
			<p className={styles.description}>Chat with her AI clone like you are chatting with her in reality ⬇️</p>
			<div className={styles.textMessages}>
				<div className={classNamesModule(styles.outerMessage, styles.authorOuterMessages)}>
					<TextMessage
						text="Hey, how are you? 😈"
						isAuthor
						isBottom
						classNames={[styles.message, styles.authorMessage]}
						textClassNames={[styles.messageText]}
					/>
					<img
						src="/assets/random/noprofilepic.png"
						className={styles.profilePic}
						alt="profile picture of our ai sexting website"
					/>
				</div>
				<div className={classNamesModule(styles.outerMessage, styles.notAuthorOuterMessages)}>
					<img
						src="/assets/random/girl2.png"
						className={styles.profilePic}
						alt="chat ai girl profile photo"
					/>
					<TextMessage
						text="Heyyyy! I'm good, thanks! And you? 😘"
						isBottom
						classNames={[styles.message, styles.notAuthorMessage]}
						textClassNames={[styles.messageText]}
					/>
				</div>
				<div className={classNamesModule(styles.outerMessage, styles.authorOuterMessages)}>
					<TextMessage
						text="Doing well, send me a selfie 😏"
						isAuthor
						isBottom
						classNames={[styles.message, styles.authorMessage]}
						textClassNames={[styles.messageText]}
					/>
					<img
						src="/assets/random/noprofilepic.png"
						className={styles.profilePic}
						alt="profile picture of our ai sexting website"
					/>
				</div>
				<div className={classNamesModule(styles.outerMessage, styles.notAuthorOuterMessages)}>
					<img
						src="/assets/random/girl2.png"
						className={styles.profilePic}
						alt="example of a girl on our porn sexting ai deepfake chat"
					/>
					<TextMessage
						text="Hehe sure 💦"
						isBottom
						classNames={[styles.message, styles.notAuthorMessage]}
						textClassNames={[styles.messageText]}
					/>
				</div>
				<div className={classNamesModule(styles.outerMessage, styles.notAuthorOuterMessages)}>
					<img
						src="/assets/random/girl2.png"
						className={styles.profilePic}
						alt="chat ai with porn function, with nude girl"
					/>
					<ImageMessage
						src="/assets/random/girl3selfie.png"
						isBottom
						isAuthor={false}
						classNames={[styles.girl3selfie]}
						alt="example of a chat ai photo on our porn sexting platform"
					/>
				</div>
			</div>
			<Button classNames={[styles.bigCta]} textClassNames={[styles.bigCtaText]} onClick={handleCtaClick}>
				Turn Girl Into AI
			</Button>
			<h2 className={classNamesModule(styles.title, styles.secondTitle)}>Explore your deepest desires 🤫</h2>
			<p className={styles.description}>
				Chat with her about anything. Receive dirty photos and voice messages. 😛
			</p>
			<div className={styles.sampleChats}>
				{_.range(1, 7).map(i => (
					<img
						className={styles.chatImage}
						src={`/assets/chat/landing/chat${i}.png`}
						alt={`chat ai sexting with images example of our platform ${i}`}
					/>
				))}
			</div>
			<div className={styles.cards}>
				{/* <Card /> */}
				{cards.map((card, i, arr) => (
					<Card
						{...card}
						classNames={[
							{
								[styles.cardWithBottomSpace]: i !== arr.lenght - 1,
							},
							styles.card,
						]}
						onClick={handleCtaClick}
						ctaButtonClassNames={[styles.cardCtaButton]}
						ctaButtonTextClassNames={[styles.cardCtaButtonText]}
					/>
				))}
			</div>
			<div className={styles.betText}>
				What are <span className={styles.titleHighlight}>you</span> waiting for?
			</div>
			<div className={styles.betText}>
				Join <span className={styles.titleHighlight}>thousands</span> sexting with their dream AI girl every day
				💦
			</div>
			<Button classNames={[styles.bigCta]} onClick={handleCtaClick}>
				Turn Girl Into AI Now
			</Button>
			<FAQ classNames={[styles.faq]} />
			<UserAgreementPopup isOpen={showUserAgreementPopup} onClose={handleUserAgreementPopupClose} />
		</div>
	);
};
