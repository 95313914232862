import classNamesModule from "classnames";

import styles from "./styles.module.css";
import useChatHistory from "./useChatHistory";
import TakingPhoto from "./components/TextMessage/components/TakingPhoto/TakingPhoto";

export default ({
	events,
	innerRef,
	classNames,
	isGirlTyping,
	onPhotoLoad,
	isFirstGirlWithEvents,
	isFreeTrial,
	onCensoredClick,
	chataiPresents,
}) => {
	const { Events } = useChatHistory({
		innerRef,
		events,
		isGirlTyping,
		onPhotoLoad,
		isFreeTrial,
		onCensoredClick,
		chataiPresents,
	});

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} ref={innerRef}>
			{Events}
		</div>
	);
};
