import classNamesModule from "classnames";
import { useTranslation } from "react-i18next";

import Popup from "../../../Popup/Popup";
import styles from "./styles.module.css";
import { navJS } from "../../../../utils/navjs";

export default ({ isOpen, onClose, classNames }) => {
	const [t] = useTranslation("global");
	const iosInstructions = (
		<>
			<div className={styles.instruction}>
				{t("popUp.bottom_tab")}
				<svg
					className={styles.svg}
					width="14"
					height="19"
					viewBox="0 0 14 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_236_20)">
						<path
							d="M7.00402 12.2061C7.26939 12.2061 7.49455 11.9794 7.49455 11.7024V2.66116L7.46238 1.44391L8.17806 2.21623L9.54509 3.70212C9.63354 3.80285 9.7622 3.85323 9.88282 3.85323C10.1321 3.85323 10.3331 3.66015 10.3331 3.3999C10.3331 3.25719 10.2769 3.15646 10.1884 3.05571L7.35784 0.167896C7.23722 0.0419741 7.13268 0 7.00402 0C6.87536 0 6.77082 0.0419741 6.6502 0.167896L3.81965 3.05571C3.72315 3.15646 3.66685 3.25719 3.66685 3.3999C3.66685 3.66015 3.85986 3.85323 4.11718 3.85323C4.2378 3.85323 4.3745 3.80285 4.46295 3.70212L5.82998 2.21623L6.54566 1.44391L6.5135 2.66116V11.7024C6.5135 11.9794 6.73866 12.2061 7.00402 12.2061ZM2.3722 18.6701H11.6278C13.1959 18.6701 14 17.8306 14 16.2188V7.63929C14 6.01908 13.1959 5.17961 11.6278 5.17961H9.33601V6.22056H11.6037C12.4963 6.22056 13.0029 6.72425 13.0029 7.68965V16.1601C13.0029 17.1338 12.4963 17.6291 11.6037 17.6291H2.38829C1.48765 17.6291 0.997128 17.1338 0.997128 16.1601V7.68965C0.997128 6.72425 1.48765 6.22056 2.38829 6.22056H4.67202V5.17961H2.3722C0.804136 5.17961 0 6.01069 0 7.63929V16.2188C0 17.839 0.804136 18.6701 2.3722 18.6701Z"
							fill="#2E7CF6"
						/>
					</g>
					<defs>
						<clipPath id="clip0_236_20">
							<rect width="14" height="19" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div className={styles.instruction}>
				<span>
					2. Select <b>Add To Homescreen</b>
				</span>
				<svg
					className={styles.svg}
					width="15"
					height="15"
					viewBox="0 0 15 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_236_22)">
						<path
							fill-rule="evenodd"
							clipRule="evenodd"
							d="M2.72727 1.09091H12.2727C13.1765 1.09091 13.9091 1.82354 13.9091 2.72727V12.2727C13.9091 13.1765 13.1765 13.9091 12.2727 13.9091H2.72727C1.82354 13.9091 1.09091 13.1765 1.09091 12.2727V2.72727C1.09091 1.82354 1.82354 1.09091 2.72727 1.09091ZM0 2.72727C0 1.22104 1.22104 0 2.72727 0H12.2727C13.779 0 15 1.22104 15 2.72727V12.2727C15 13.779 13.779 15 12.2727 15H2.72727C1.22104 15 0 13.779 0 12.2727V2.72727ZM7.63636 4.09091C7.9376 4.09091 8.18182 4.33512 8.18182 4.63636V6.81818H10.3636C10.6649 6.81818 10.9091 7.0624 10.9091 7.36364C10.9091 7.66488 10.6649 7.90909 10.3636 7.90909H8.18182V10.3636C8.18182 10.6649 7.9376 10.9091 7.63636 10.9091C7.33512 10.9091 7.09091 10.6649 7.09091 10.3636V7.90909H4.63636C4.33512 7.90909 4.09091 7.66488 4.09091 7.36364C4.09091 7.0624 4.33512 6.81818 4.63636 6.81818H7.09091V4.63636C7.09091 4.33512 7.33512 4.09091 7.63636 4.09091Z"
							fill="#DADADA"
						/>
					</g>
					<defs>
						<clipPath id="clip0_236_22">
							<rect width="15" height="15" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
		</>
	);

	const androidInstructions = (
		<>
			<div className={styles.instruction}>
				{t("popUp.again_1_tap")}
				<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_237_83)">
						<path
							d="M5.64355 13.1432C5.64355 14.1694 6.47369 15 7.49943 15C8.52517 15 9.35577 14.1703 9.35577 13.1432C9.35577 12.1189 8.52657 11.2873 7.49943 11.2873C6.47369 11.2873 5.64355 12.1189 5.64355 13.1432ZM5.64355 7.40384C5.64355 8.4277 6.47322 9.26065 7.49896 9.26065C8.5247 9.26065 9.3553 8.42864 9.3553 7.40384C9.3553 6.37904 8.5261 5.54609 7.49896 5.54609C6.47369 5.54703 5.64355 6.37904 5.64355 7.40384ZM5.64355 1.85541C5.64355 2.88114 6.47369 3.71268 7.4999 3.71268C8.5247 3.71268 9.3553 2.88255 9.3553 1.85541C9.3553 0.83154 8.5261 4.80425e-07 7.4999 5.25281e-07C6.47369 5.70138e-07 5.64355 0.83154 5.64355 1.85541Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_237_83">
							<rect width="15" height="15" fill="white" transform="translate(0 15) rotate(-90)" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div className={styles.instruction}>
				<span>
					2. Select <b>add to homescreen</b>
				</span>
			</div>
		</>
	);

	return (
		<Popup
			className={classNamesModule(...(classNames || []))}
			isOpen={isOpen}
			onClose={onClose}
			closeOnOutsideClick>
			<div className={styles.container}>
				<img className={styles.logo} src="/assets/random/appIcon.png" alt="Logo" />
				<div className={styles.title}>{t("popUp.get_the_undress_app")}</div>
				<div className={styles.description}>{t("popUp.undress_easier_ste")}</div>
				<div className={styles.instructions}>{navJS.isIOS() ? iosInstructions : androidInstructions}</div>
			</div>
		</Popup>
	);
};
