import classNamesModule from "classnames";
import styles from "./styles.module.css";

export default ({ classNames, emoji, title, description, style }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={style}>
			<div className={styles.emoji}>{emoji}</div>
			<div className={styles.textContainer}>
				<h2 className={styles.title}>{title}</h2>
				<p className={styles.description}>{description}</p>
			</div>
		</div>
	);
};
