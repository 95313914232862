export const adTask = {
	type: "ad",
	title: "View a short ad",
	coinReward: 3,
};

export const joinChannelTask = {
	type: "joinChannel",
	title: "Join a channel",
	coinReward: 1,
};

export const appTask = {
	type: "app",
	title: "Download an app",
	coinReward: 3,
};

export const casinoTask = {
	type: "casino",
	title: "Have fun in casino",
	coinReward: 222,
};

export const casinoTask2 = {
	type: "casino2",
	title: "Have fun in casino #2",
	coinReward: 777,
};

export const chaturbateTask = {
	type: "chaturbate",
	title: "Upvote",
	coinReward: 3,
};

export const twitterTask = {
	type: "twitter",
	title: "Twitter",
	coinReward: 4,
};

export const whatsappTask = {
	type: "whatsapp",
	title: "Whatsapp",
	coinReward: 4,
};

export const joinNewsTask = {
	type: "joinNews",
	title: "Stay up to date",
	coinReward: 3,
};

export const clickTask = {
	type: "clickTask",
};

export default {
	adTask,
	joinChannelTask,
	appTask,
	casinoTask,
	casinoTask2,
	chaturbateTask,
	twitterTask,
	whatsappTask,
	joinNewsTask,
	clickTask,
};
