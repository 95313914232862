import Button from "../../../../../../components/Button/Button";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, onFileChange, isReady, onSubmit }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<Button
				classNames={[styles.button, styles.uploadButton]}
				textClassNames={[styles.buttonText, styles.uploadButtonText]}>
				<input type="file" className={styles.input} multiple onChange={onFileChange} />
				<img className={styles.uploadImg} src="/assets/random/upload.png" />
				Upload photos of her
			</Button>
			<Button
				classNames={[styles.button, styles.startButton]}
				isDeactivated={!isReady}
				onClick={onSubmit}
				textClassNames={[styles.buttonText]}>
				Turn Girl Into AI
			</Button>
		</div>
	);
};
