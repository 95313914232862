import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { getLoginLink } from "../../utils/etc";
import { submitGtagEvent } from "../../utils/gtag";
import axiosInstance from "../../axios/axiosInstance";
import ai from "../../utils/ai";
import useTracking from "../../uses/useTracking";

export default () => {
	const {} = useTracking();

	const [t] = useTranslation("global");
	const handleStartClick = () => {
		setTimeout($ => {}, 800);
	};

	const [showLoginPopup, setShowLoginPopup] = useState(false);

	const handleLoginPopupClose = () => {
		setShowLoginPopup(false);
	};

	const [showUserAgreementPopup, setShowUserAgreementPopup] = useState(false);

	const handleUserAgreementPopupClose = () => {
		setShowUserAgreementPopup(false);
	};

	const [userAgreementAccepted, setUserAgreementAccepted] = useLocalStorage("userAgreementAccepted", false);

	const [isCtaLoading, setIsCtaLoading] = useState(false);

	const [selectedUndressPhoto, setSelectedUndressPhoto] = useState(null);
	const [isUndressing, setIsUndressing] = useState(false);
	const [isUndressingDone, setIsUndressingDone] = useState(false);
	const [hasUndressedForFreeBefore, setHasUndressedForFreeBefore] = useLocalStorage(
		"hasUndressedForFreeBefore",
		false,
	);

	const handleUndressingDone = () => {
		if (undressResultFilepathRef.current) {
			window.location = `/free-undress-result?filepath=${undressResultFilepathRef.current}`;
			return;
		}

		if (!undressResultFilepathRef.current) {
			setShowLoginPopup(true);
			return;
		}

		// if (!undressResultFilepathRef.current) {
		// 	setIsUndressing(false);
		// 	setShowLoginPopup(true);
		// 	return;
		// }
	};

	// useEffect(
	// 	$ => {
	// 		if (userAgreementAccepted) {
	// 			return;
	// 		}

	// 		setShowUserAgreementPopup(true);
	// 	},
	// 	[userAgreementAccepted],
	// );

	const handleUserAgreementAccept = () => {
		setUserAgreementAccepted(true);
		setShowUserAgreementPopup(false);
		window.location.href = getLoginLink({ origin, ref, isAdVersion });
	};

	const handleUserAgreementDecline = () => {
		setShowUserAgreementPopup(false);
	};

	const [origin, setOrigin] = useLocalStorage("origin", null);
	const [ttoken, setTtoken] = useLocalStorage("ttoken", null);
	const [oauthRefreshToken, setOauthRefreshToken] = useLocalStorage("oauthRefreshToken", null);
	const [ref, setRef] = useLocalStorage("ref", null);
	const [uref, setUref] = useLocalStorage("uref", null);
	const [isAdVersion, setIsAdVersion] = useLocalStorage("isAdVersion", null);

	const undressResultFilepathRef = useRef();

	const [searchParams] = useSearchParams();

	useEffect(
		$ => {
			if (searchParams.has("ref")) {
				setRef(searchParams.get("ref"));
			}

			if (searchParams.has("uref")) {
				setUref(searchParams.get("uref"));
			}

			if (searchParams.has("ttoken")) {
				setTtoken(searchParams.get("ttoken"));
			}

			if (searchParams.has("av")) {
				setIsAdVersion(true);
			}
		},
		[searchParams],
	);

	// <Benefit
	// 			emoji="⚡"
	// 			title="Undress instantly"
	// 			description="Our powerful undress AI only takes a few seconds to nudify a photo"
	// 		/>
	// 		<Benefit
	// 			emoji="🔐"
	// 			title="All nudes are yours"
	// 			description="All undresses as well as your uploaded photos are securely stored using latest SHA512 encryption"
	// 		/>
	// 		<Benefit
	// 			emoji="😈"
	// 			title="See what others don’t"
	// 			description="Be the only one who has nudes from her, everyone else would beg to have what you have"
	// 		/>

	const benefits = [
		{
			emoji: "⚡",
			title: "Undress instantly",
			description: "Our powerful undress AI only takes a few seconds to nudify a photo",
		},
		{
			emoji: "🔐",
			title: "All deepnudes are yours",
			description:
				"All undresses as well as your uploaded photos are securely processed using latest SHA512 encryption",
		},
		{
			emoji: "😌",
			title: "See what others don’t",
			description: "Be the only one who has nudes from her, everyone else would beg to have what you have",
		},
	];

	const handleCtaSubmit = async file => {
		if (hasUndressedForFreeBefore) {
			setShowLoginPopup(true);
			return;
		}

		setIsCtaLoading(true);

		let isReadyRes;
		try {
			isReadyRes = await axiosInstance.get("/ai/undress-free/is-ready");
		} catch (err) {
			setIsCtaLoading(false);
			setShowLoginPopup(true);
			return;
		}

		setIsCtaLoading(false);

		if (!isReadyRes.data.isReady) {
			setShowLoginPopup(true);
			return;
		}

		setSelectedUndressPhoto(file);
		setIsUndressing(true);
		setHasUndressedForFreeBefore(true);

		const { error, filepath } = await ai.freeUndress({ fileToUndress: file });

		if (error) {
			setShowLoginPopup(true);
			setIsUndressing(false);
			return;
		}

		undressResultFilepathRef.current = filepath;

		setIsUndressingDone(true);
	};

	const handleCtaClick = ({ event, googlePrefix }) => {
		submitGtagEvent("landingpage_cta_click", {
			position: googlePrefix,
			isLoggedIn: !!ttoken,
		});

		if (ttoken || oauthRefreshToken) {
			event.preventDefault();
			window.location.href = "/upload";
			return;
		}

		return;
	};

	const handleLoginTextClick = e => {
		e.preventDefault();

		if (ttoken || oauthRefreshToken) {
			submitGtagEvent("login_landing_already_logged_in");
			window.location.href = "/upload";
			return;
		}

		submitGtagEvent("login_landing_not_logged_in");

		setShowLoginPopup(true);
	};

	return {
		handleStartClick,
		showUserAgreementPopup,
		handleUserAgreementAccept,
		handleUserAgreementDecline,
		benefits,
		handleCtaSubmit,
		isCtaLoading,
		handleCtaClick,
		handleLoginTextClick,
		handleLoginPopupClose,
		showLoginPopup,
		t,
		handleUserAgreementPopupClose,
		isUndressing,
		isUndressingDone,
		handleUndressingDone,
		selectedUndressPhoto,
	};
};
