import classNamesModule from "classnames";
import Footer from "../../components/Footer/Footer";
import Link from "../../components/Link/Link";
import LoginPopup from "../Upload/components/LoginPopup/LoginPopup";
import UserAgreementPopup from "../Upload/components/UserAgreementPopup/UserAgreementPopup";
import Benefit from "./components/Benefit/Benefit";
import Cta from "./components/Cta/Cta";
import Example from "./components/Example/Example";
import ExampleCTA from "./components/ExampleCTA/ExampleCTA";
import FAQ from "./components/FAQ/FAQ";
import Proof from "./components/Proof/Proof";
import Shh from "./components/Shh/Shh";
import styles from "./styles.module.css";
import useHomepage from "./useHomepage";
import { Helmet } from "react-helmet";
import Card from "./components/Card/Card";
import Button from "../../components/Button/Button";
import TrustMeBro from "./components/TrustMeBro/TrustMeBro";
import Loading from "../Upload/components/Loading/Loading";
import HeadSeo from "../../components/HeadSeo/HeadSeo";
import seo from "../../constants/seo";

export default ({ classNames }) => {
	const {
		handleStartClick,
		showUserAgreementPopup,
		handleUserAgreementAccept,
		handleUserAgreementDecline,
		benefits,
		handleCtaSubmit,
		isCtaLoading,
		handleCtaClick,
		handleLoginTextClick,
		handleLoginPopupClose,
		showLoginPopup,
		t,
		handleUserAgreementPopupClose,
		isUndressing,
		isUndressingDone,
		handleUndressingDone,
		selectedUndressPhoto,
	} = useHomepage({});

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))}>
			<HeadSeo canonical="https://undressai.com/" />
			{isUndressing && (
				<div className={styles.undressingContainer}>
					<div className={styles.undressingTitle}>
						undress<span className={styles.undressAiAi}>AI</span>.com is undressing...
					</div>
					<img
						src={selectedUndressPhoto ? URL.createObjectURL(selectedUndressPhoto) : ""}
						className={styles.undressingImg}
					/>
					<div className={styles.undressingLoadingContainer}>
						<Loading
							isStarted={isUndressing}
							expectedCompletionTime={50_000}
							additionalCompletionTime={1000}
							classNames={[styles.samingLoading]}
							updateInterval={100}
							onFinish={handleUndressingDone}
							isDone={isUndressingDone}
						/>
					</div>
				</div>
			)}
			<Link href="/upload" classNames={[styles.loginText]} onClick={handleLoginTextClick}>
				Login
			</Link>
			<section className={styles.hero}>
				<div className={styles.heroText}>
					<img src="/assets/undresslanding/logo.webp" className={styles.logo} alt="undressai.com logo" />
					<h1 className={styles.title}>
						Undress <span className={styles.titleHighlight}>anyone</span> with deepnude
					</h1>
					<h2 className={styles.description}>
						Our groundbreaking undress AI deepnude system removes clothes from any photo within seconds
					</h2>
				</div>
				<Proof
					beforeImage="/assets/undresslanding/proofBefore.webp"
					afterImage="/assets/undresslanding/proofAfter.webp"
					classNames={[styles.proof]}
				/>
				<Cta
					onClick={e => handleCtaClick({ event: e, googlePrefix: "top" })}
					onSubmit={handleCtaSubmit}
					isLoading={isCtaLoading}
				/>
			</section>
			<p className={styles.imagineText}>
				“<span className={styles.highlight}>Imagine</span> wasting time taking her out on dates, when you can
				just use <span className={styles.highlight}>Undress AI</span> to get her nudes 😌”
			</p>
			<section id="examples">
				{[1, 2, 3].map((i, $, arr) => (
					<Example
						beforeSrc={`/assets/undresslanding/${i}before.webp`}
						afterSrc={`/assets/undresslanding/${i}after.webp`}
						classNames={[styles.example]}
					/>
				))}
				<ExampleCTA
					onClick={e => handleCtaClick({ event: e, googlePrefix: "example" })}
					onSubmit={handleCtaSubmit}
					isLoading={isCtaLoading}
				/>
			</section>
			<Cta
				onClick={e => handleCtaClick({ event: e, googlePrefix: "underexamples" })}
				onSubmit={handleCtaSubmit}
				isLoading={isCtaLoading}
				classNames={[styles.ctaAfterExamples]}
			/>
			<section className={styles.benefitsContainer}>
				{benefits.map((b, i, arr) => (
					<Benefit {...b} style={{ marginBottom: i !== arr.length - 1 ? "1rem" : 0 }} />
				))}
			</section>
			<section className={styles.cardContainer}>
				<Card
					CtaComponent={
						<Cta
							classNames={styles.affiliateCta}
							onClick={e => handleCtaClick({ event: e, googlePrefix: "affiliate" })}
							onSubmit={handleCtaSubmit}
							isLoading={isCtaLoading}>
							Invite Now
						</Cta>
					}
					subTitle="💎 Affiliate program"
					title="Use undress AI for free"
					description="For each friend you invite, you get a dollar commission or free Undress AI coins, which can be used to remove clothes from photos!"
					classNames={[styles.affiliateCard]}
				/>
				<Card
					CtaComponent={
						<Cta
							classNames={styles.newUpdateCta}
							onClick={e => handleCtaClick({ event: e, googlePrefix: "newupdate" })}
							onSubmit={handleCtaSubmit}
							isLoading={isCtaLoading}>
							Try now
						</Cta>
					}
					subTitle="🎉 New Update"
					title="Customize your undress"
					description="We are exited to announce our newest update, that let’s you customize your undress! You can now specify clothes, body hair and much more."
					onClick={e => handleCtaClick({ event: e, googlePrefix: "newupdate" })}
					classNames={[styles.newUpdateCard]}
				/>
			</section>
			<section className={styles.trustMeBroContainer}>
				<TrustMeBro />
				<Cta
					onClick={e => handleCtaClick({ event: e, googlePrefix: "underexamples" })}
					onSubmit={handleCtaSubmit}
					isLoading={isCtaLoading}
					classNames={[styles.ctaAfterExamples]}
				/>
			</section>
			<UserAgreementPopup
				isOpen={showUserAgreementPopup}
				onAgree={handleUserAgreementAccept}
				onDecline={handleUserAgreementDecline}
				onClose={handleUserAgreementPopupClose}
			/>
			<section className={styles.faq} id="faq">
				<FAQ />
			</section>
			<Footer />
			<LoginPopup isOpen={showLoginPopup} onClose={handleLoginPopupClose} />
		</div>
	);
};
