import { useMemo } from "react";

import _conStyles from "./conStyles";
import string from "../../utils/string";
import { authors } from "../../constants/events";
import TextMessage from "./components/TextMessage/TextMessage";
import ImageMessage from "./components/ImageMessage/ImageMessage";
import VoiceMessage from "./components/VoiceMessage/VoiceMessage";
import Answers from "./components/Answers/Answers";
import { UNBREAKABLE_SPACE } from "../../constants/unicode";
import styles from "./styles.module.css";
import { useUser } from "../../contexts/User/User";
import StickerMessage from "./components/StickerMessage/StickerMessage";

export default ({ events, onAnswerClick, isGirlTyping, onPhotoLoad, isFreeTrial, onCensoredClick, chataiPresents }) => {
	const { user } = useUser();

	const Events = useMemo(
		$ =>
			events
				?.map((event, eventsI) => {
					if (!isGirlTyping && event.type === "typing" && event.author === authors.BOT) {
						return;
					}

					if (event.type === "text" && (event.presentID || event.presentFilepath)) {
						const isAuthor = event.author === authors.USER;
						const eventBefore = events[eventsI - 1];
						const eventAfter = events[eventsI + 1];
						const isBottom = (!eventBefore && !eventAfter) || eventAfter?.author !== event.author;
						const isTop = !eventBefore || eventBefore?.author !== event.author;

						return (
							<StickerMessage
								text={event.content}
								isAuthor={event.type !== "typing" && isAuthor}
								isBottom={isBottom}
								isTop={isTop}
								timestamp={event.timestamp}
								isAction={event.type === "action"}
								key={eventsI.toString()}
								style={_conStyles({ isTop, isBottom }).conTextMessage}
								date={event.date}
								presentIcon={
									event.presentFilepath || chataiPresents?.find(p => p.id === event.presentID)?.image
								}
							/>
						);
					}

					if (
						event.type === "text" ||
						event.type === "typing" ||
						event.type === "takingPhoto" ||
						event.type === "recordingVoice" ||
						event.type === "lookingAtPhoto"
					) {
						const isAuthor = event.author === authors.USER;
						const eventBefore = events[eventsI - 1];
						const eventAfter = events[eventsI + 1];
						const isBottom = (!eventBefore && !eventAfter) || eventAfter?.author !== event.author;
						const isTop = !eventBefore || eventBefore?.author !== event.author;

						return (
							<TextMessage
								text={event.content}
								isAuthor={event.type !== "typing" && isAuthor}
								isBottom={isBottom}
								isTop={isTop}
								timestamp={event.timestamp}
								isAction={event.type === "action"}
								key={eventsI.toString()}
								style={_conStyles({ isTop, isBottom }).conTextMessage}
								isTyping={event.type === "typing" && isBottom}
								isTakingPhoto={event.type === "takingPhoto" && isBottom}
								isRecordingVoice={event.type === "recordingVoice" && isBottom}
								isLookingAtPhoto={event.type === "lookingAtPhoto" && isBottom}
								date={event.date}
							/>
						);
					}

					if (event.type === "photo") {
						const isAuthor = event.author === authors.USER;
						const isBottom = events[eventsI].length - 1;
						const isTop = false;

						return (
							<ImageMessage
								isAuthor={isAuthor}
								isBottom={isBottom}
								isTop={isTop}
								timestamp={event.timestamp}
								src={
									event.content.startsWith("file://")
										? `${process.env.REACT_APP_S3_ENDPOINT}/${event.content.replace("file://", "")}`
										: event.content
								}
								style={
									_conStyles({
										isTop,
										isBottom,
									}).conTextMessage
								}
								key={eventsI.toString()}
								date={event.date}
								text={event.photoCaption}
								onPhotoLoad={onPhotoLoad}
								onCensoredClick={
									!user.orders?.find(o => o.dollarAmount > 0) && event.isExplicit && onCensoredClick
								}
							/>
						);
					}

					if (event.type === "voice") {
						const isAuthor = event.author === authors.USER;
						const isBottom = events[eventsI].length - 1;
						const isTop = false;

						return (
							<VoiceMessage
								isAuthor={isAuthor}
								isBottom={isBottom}
								isTop={isTop}
								timestamp={event.timestamp}
								src={
									event.content.startsWith("file://")
										? `${process.env.REACT_APP_S3_ENDPOINT}/${event.content.replace("file://", "")}`
										: event.content
								}
								duration={string.getCounter(event.durationSeconds)}
								style={_conStyles({ isTop, isBottom }).conTextMessage}
								key={eventsI.toString()}
								date={event.date}
								text={event.photoCaption}
							/>
						);
					}

					const eventBefore = events[eventsI - 1];

					if (event.type === "costReminder" && (!eventBefore || eventBefore?.type !== "costReminder")) {
						return;

						let userMetadata = {};

						try {
							userMetadata = JSON.parse(user.metadata);
						} catch (e) {}

						const pricePerMessage = userMetadata.chataiPricePerMessage || 2;

						return (
							<div className={styles.costText}>
								{pricePerMessage} coins per message sent
								{event.isFreeTrial ? `, free for the next 10\u00A0messages` : ""}
								{UNBREAKABLE_SPACE}
							</div>
						);
					}
				})
				.filter(Boolean),
		[user, events],
	);

	return {
		Events,
	};
};
