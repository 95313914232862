export const sleep = async ms => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const takeAtLeast = async (fn, ms) => {
	const startTime = Date.now();

	const result = await fn();

	const endTime = Date.now();
	const elapsedTime = endTime - startTime;

	const remainingTime = ms - elapsedTime;

	if (remainingTime > 0) {
		await new Promise(resolve => setTimeout(resolve, remainingTime));
	}

	return result;
};

export default {
	sleep,
	takeAtLeast,
};
