import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<div className={styles.titleContainer}>
				<h2 className={styles.titleText}>Undress AI is completely safe.</h2>
				<img className={styles.titleIcon} src="/assets/undresslanding/verified.png" />
			</div>
			<p className={styles.description}>
				Undress AI is highly secure and discrete. Your actions are not published anywhere.
			</p>
		</div>
	);
};
