import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import classNamesModule from "classnames";

import styles from "./styles.module.css";

export default ({ onClose, isOpen, children, classNames, closeOnOutsideClick, opacity, style }) => {
	return (
		<Popup
			className={classNamesModule(styles.container, ...(classNames || []))}
			contentStyle={{ border: "none", ...style }}
			onClose={onClose}
			open={isOpen}
			closeOnDocumentClick={closeOnOutsideClick || false}
			nested
			overlayStyle={{ background: `rgba(0,0,0,${opacity || 0.5})` }}>
			{children}
			<div className={styles.closeButton} onClick={onClose}>
				<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_187_2)">
						<path
							d="M9.28044 7.5001L14.8516 1.92925C15.0494 1.73142 15.0494 1.41129 14.8516 1.21352L13.7869 0.148395C13.6921 0.0536304 13.5634 0.000274658 13.4291 0.000274658C13.2947 0.000274658 13.166 0.0536304 13.0712 0.148395L7.50001 5.71966L1.92881 0.148395C1.83398 0.0536304 1.70529 0.000274658 1.57094 0.000274658C1.43663 0.000274658 1.30791 0.0536304 1.21311 0.148395L0.148372 1.21352C-0.0494574 1.41129 -0.0494574 1.73142 0.148372 1.92925L5.71957 7.50013L0.148926 13.0703C-0.0488064 13.2681 -0.0488064 13.5883 0.148926 13.786L1.21373 14.8512C1.30849 14.9459 1.43721 14.9993 1.57156 14.9993C1.70591 14.9993 1.8346 14.9459 1.92946 14.8512L7.49998 9.28056L13.0705 14.8512C13.1654 14.9459 13.294 14.9993 13.4284 14.9993C13.5628 14.9993 13.6915 14.9459 13.7862 14.8512L14.851 13.786C15.0488 13.5883 15.0488 13.2681 14.851 13.0703L9.28044 7.5001Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_187_2">
							<rect width="15" height="15" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
		</Popup>
	);
};
