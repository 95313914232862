import classNamesModule from "classnames";
import Link from "../Link/Link";
import styles from "./styles.module.css";

export default ({ classNames, style }) => {
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<div className={styles.links}>
				<Link href="/" classNames={[styles.link]}>
					Homepage
				</Link>
				<Link href="/user-agreement" classNames={[styles.link]}>
					User agreement
				</Link>
				<Link href="/#faq" classNames={[styles.link]}>
					Faq
				</Link>
				<Link href="/#examples" classNames={[styles.link]}>
					Examples
				</Link>
				<Link href="/blog" classNames={[styles.link]}>
					Blog
				</Link>
				<Link href="https://coin.undressai.com" classNames={[styles.link]}>
					Crypto Coin
				</Link>
				<Link href="https://undressai.com/earn" classNames={[styles.link]}>
					Affiliate Program
				</Link>
				<Link href="/" classNames={[styles.link]}>
					support@undressai.com
				</Link>
			</div>
		</div>
	);
};
