import Button from "../../../../components/Button/Button";
import ChatHistory from "../../../../components/ChatHistory/ChatHistory";
import ImageMessage from "../../../../components/ChatHistory/components/ImageMessage/ImageMessage";
import TextMessage from "../../../../components/ChatHistory/components/TextMessage/TextMessage";
import { authors } from "../../../../constants/events";
import Card from "./components/Card/Card";
import FullCta from "./components/FullCta/FullCta";
import styles from "./styles.module.css";
import classNamesModule from "classnames";
import useLandingPage from "./useLandingPage";
import FAQ from "./components/FAQ/FAQ";
import { UNBREAKABLE_SPACE } from "../../../../constants/unicode";
import { Helmet } from "react-helmet";
import Link from "../../../../components/Link/Link";
import UserAgreementPopup from "../../../Upload/components/UserAgreementPopup/UserAgreementPopup";
import _ from "lodash";
import HeadSeo from "../../../../components/HeadSeo/HeadSeo";

export default ({ classNames, style }) => {
	const {
		cards,
		handleFaceFilesChange,
		faceFilesBase64,
		handleFilesSubmit,
		isSeoLandingPage,
		handleLoginTextClick,
		handleLoginPopupClose,
		showLoginPopup,
		showUserAgreementPopup,
		handleUserAgreementPopupClose,
		heroRef,
		handleCtaClick,
	} = useLandingPage();

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }} ref={heroRef}>
			<HeadSeo
				title="Generate AI Porn Photos & Videos (With deepfake) | Undress AI Deepnude App"
				description="Generate porn videos & photos with our advanced AI. It only takes a few seconds to create AI porn images. Fake swap with our deepfake technology."
				canonical="https://undressai.com/deepfake-porn-generator"
			/>
			{isSeoLandingPage && (
				<Link href="/chats" classNames={[styles.loginText]} onClick={handleLoginTextClick}>
					Login
				</Link>
			)}
			<div
				className={styles.hero}
				id="hero"
				style={
					{
						// height:
						// 	window.location.pathname === "/chats"
						// 		? "calc(100vh - 3.5 * 2rem - 1rem - 0.5rem - 1rem)"
						// 		: "100vh",
					}
				}>
				<h1 className={styles.title}>
					Create <span className={styles.titleHighlight}>AI Deepfake Porn</span> of your crush
					{UNBREAKABLE_SPACE}
					🤭
				</h1>
				<section className={styles.exampleWorkflowContainer}>
					<img className={styles.otherInputImg} src="/assets/generatelanding/girlinput2.png" />
					<img className={styles.otherOutputArrow} src="/assets/generatelanding/rightarrow.png" />
					<div className={styles.otherOutputsContainer}>
						{_.range(1, 7).map(i => (
							<img
								className={styles.otherOutputImg}
								src={`/assets/generatelanding/otheroutput${i}.png`}
								alt={`deepfake porn AI photo example ${i}`}
							/>
						))}
					</div>
				</section>
				<FullCta
					classNames={[styles.fullCta]}
					onFileChange={handleFaceFilesChange}
					isReady={faceFilesBase64.length}
					onSubmit={handleFilesSubmit}
				/>
			</div>
			<div className={styles.cards}>
				{cards.slice(0, 1).map((card, i, arr) => (
					<Card
						{...card}
						classNames={[
							{
								[styles.cardWithBottomSpace]: i !== arr.lenght - 1,
							},
							styles.card,
						]}
						onClick={handleCtaClick}
						ctaButtonClassNames={[styles.cardCtaButton]}
						ctaButtonTextClassNames={[styles.cardCtaButtonText]}
					/>
				))}
			</div>
			<h2 className={classNamesModule(styles.title, styles.secondTitle)}>
				😏 Upload a photo of her, enjoy <span className={styles.titleHighlight}>super sexy porn photos</span>
			</h2>
			<p className={styles.description}>
				It literally takes 3 seconds to see her spreading her pussy or doing everything you want ⬇️
			</p>
			<div className={styles.smallInputOutputExampleContainer}>
				<img
					className={styles.smallInputImg}
					src="/assets/generatelanding/girlinput1.png"
					alt="porn deepfake ai photo"
				/>
				<img
					className={styles.smallOutputArrow}
					src="/assets/generatelanding/rightarrow.png"
					alt="create ai porn online photo"
				/>
				<img
					className={styles.smallOutputImg}
					src="/assets/generatelanding/girloutput1.png"
					ale="porn generator with deepfake ai"
				/>
			</div>
			<Button classNames={[styles.bigCta]} textClassNames={[styles.bigCtaText]} onClick={handleCtaClick}>
				Create Deepfake Porn
			</Button>
			<div className={styles.cards}>
				{cards.slice(1).map((card, i, arr) => (
					<Card
						{...card}
						classNames={[
							{
								[styles.cardWithBottomSpace]: i !== arr.lenght - 1,
							},
							styles.card,
						]}
						onClick={handleCtaClick}
						ctaButtonClassNames={[styles.cardCtaButton]}
						ctaButtonTextClassNames={[styles.cardCtaButtonText]}
					/>
				))}
			</div>
			<div className={styles.betText}>
				What are <span className={styles.titleHighlight}>you</span> waiting for?
			</div>
			<div className={styles.betText}>
				Join <span className={styles.titleHighlight}>thousands</span> creating hot deepfake porn every day 💦
			</div>
			<Button classNames={[styles.bigCta]} onClick={handleCtaClick}>
				Create Deepfake Porn Now
			</Button>
			<FAQ classNames={[styles.faq]} />
			<UserAgreementPopup isOpen={showUserAgreementPopup} onClose={handleUserAgreementPopupClose} />
		</div>
	);
};
