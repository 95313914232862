import classNamesModule from "classnames";
import React, { useState, useEffect, useMemo, useRef } from "react";

import _conStyles from "./conStyles";
import styles from "./styles.module.css";
import { msSinceDate } from "../../../../utils/time";

function easeOutQuart(x) {
	return 1 - Math.pow(1 - x, 4);
}

export default ({
	classNames,
	isStarted,
	isDone,
	expectedCompletionTime,
	updateInterval,
	additionalCompletionTime,
	onFinish,
	isCancelled,
}) => {
	const [progress, setProgress] = useState(0);
	const conStyles = useMemo($ => _conStyles({ progress }), [progress]);

	useEffect(() => {
		if (isCancelled) {
			setProgress(0);
			return;
		}

		if (!isStarted && progress <= 0) {
			setProgress(0);
			return;
		}

		if (isDone) {
			console.log("ITS DONE!", { progress, additionalCompletionTime, updateInterval });

			const remaining = 100 - progress;
			const increment = remaining / (additionalCompletionTime / updateInterval);

			const timer = setInterval(() => {
				setProgress(prevProgress => Math.min(prevProgress + increment, 100));
			}, updateInterval);

			return () => clearInterval(timer);
		}

		if (!isDone) {
			// Get the start time at the beginning of the effect
			const startTime = Date.now();

			const timer = setInterval(() => {
				// Calculate the elapsed time, scaled to be between 0 and 1
				const elapsedTime = (Date.now() - startTime) / expectedCompletionTime;

				// Set the progress to the eased elapsed time (scaled to be between 0 and 99.09)
				setProgress(easeOutQuart(Math.min(elapsedTime, 1)) * 99.09);
			}, updateInterval);

			return () => clearInterval(timer);
		}
	}, [isDone, expectedCompletionTime, updateInterval, additionalCompletionTime, isStarted, isCancelled]);

	const lastOnFinishCallRef = useRef();

	useEffect(() => {
		const msSinceLastFinishCall = msSinceDate(lastOnFinishCallRef.current);

		console.log({ msSinceLastFinishCall });

		if (msSinceLastFinishCall && msSinceLastFinishCall < 1000) {
			console.log("Ms since last finish call too not long ago.:)");
			return;
		}

		if (progress === 100) {
			lastOnFinishCallRef.current = new Date();
			onFinish();
		}
	}, [progress, onFinish]);

	return (
		<div className={classNamesModule(styles.progressContainer, ...(classNames || []))}>
			<div className={styles.progressBar} style={conStyles.conProgress}>
				{parseInt(progress)}%
			</div>
		</div>
	);
};
