import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";
import Popup from "../../../../components/Popup/Popup";
import Button from "../../../../components/Button/Button";
import usePopup from "./usePopup";

export default ({
	isOpen,
	handleChooseOwnImage,
	handleUndress,
	handleOnCloseChooseRandomGirlModal,
	girls,
	sliderRef,
	handleNavigateNextImage,
	handleNavigatePrevImage,
	onGirlChoose,
}) => {
	const { currentGirl, currentGirlIndex, handleNextClick, handlePrevClick } = usePopup({ girls });

	return (
		<Popup isOpen={isOpen} onClose={handleOnCloseChooseRandomGirlModal} closeOnOutsideClick>
			<div className={styles.girlsContainer} ref={sliderRef}>
				<img src={currentGirl.beforeSrc} className={styles.girl} alt={"girl"} />
			</div>
			<div className={styles.modalIcons}>
				<img
					onClick={handlePrevClick}
					src="../../assets/funnel/left-icon.png"
					className={styles.leftIcon}
					alt="left modal icon"
				/>
				<img
					onClick={handleNextClick}
					src="../../assets/funnel/right-icon.png"
					className={styles.rightIcon}
					alt="right modal icon"
				/>
			</div>
			<div className={styles.buttonsContainer}>
				<Button style={{ backgroundColor: "#22272D" }} classNames={[styles.button, styles.leftButton]}>
					Choose own
					<input
						type="file"
						className={styles.input}
						onChange={handleChooseOwnImage}
						accept=".png,.jpg,.jpeg"
					/>
				</Button>
				<Button
					onClick={$ => onGirlChoose({ girl: currentGirl })}
					classNames={[styles.button, styles.rightButton]}>
					Undress Her
				</Button>
			</div>
		</Popup>
	);
};
