import classNamesModule from "classnames";

import useLink from "./useLink";
import styles from "./styles.module.css";

export default ({ href, target, children, classNames, onClick }) => {
	const { handleClick } = useLink({ href, target, onClick });

	return (
		<a
			className={classNamesModule("link", styles.container, ...(classNames || []))}
			onClick={handleClick}
			href={href}
			target={target}>
			{children}
		</a>
	);
};
