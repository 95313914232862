export default ({ width }) => ({
	conContainerChild: {
		position: "relative",
		width,
		height: `calc(${width} * 1.24958949097)`,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// margin: "4rem 0",
	},
});
