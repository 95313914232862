import axios from "axios";
import axiosInstance from "../axios/axiosInstance";
import { submitGtagEvent } from "./gtag";

export const uploadToUndress = async ({ photoInput, positivePrompt, negativePrompt }) => {
	const form = new FormData();
	form.append("file", photoInput);
	form.append("positivePrompt", positivePrompt);
	form.append("negativePrompt", negativePrompt);

	const res = await axiosInstance.post("/storage/upload", form, {
		headers: { "content-type": "multipart/form-data" },
	});

	const { id, hasProcessingItem, hasAdVersionTrialEnded, hasEnoughCoins, userUndressCount } = res.data;

	submitGtagEvent("undress_submit", {
		id,
		hasProcessingItem,
		hasAdVersionTrialEnded,
		hasEnoughCoins,
		positivePrompt,
		negativePrompt,
	});

	if (userUndressCount) {
		window.gtag?.("set", "user_properties", {
			user_undress_count: userUndressCount,
		});
	}

	return {
		id,
		hasProcessingItem,
		hasAdVersionTrialEnded,
		hasEnoughCoins,
	};

	// const uploadURLRes = await axiosInstance.get(`/storage/upload-url?contenttype=${photoInput.type}`);

	// const { url, filename, contentType } = uploadURLRes.data;

	// await axios.put(url, photoInput, {
	// 	headers: {
	// 		"Content-Type": contentType,
	// 		"x-amz-acl": "public-read",
	// 	},
	// });

	// const res = await axiosInstance.post("/storage/upload", {
	// 	filename,
	// 	positivePrompt,
	// 	negativePrompt,
	// });

	// return {
	// 	id: res.data.id,
	// 	isCreated: res.data.isCreated,
	// 	alreadyProcessing: res.data.alreadyProcessing,
	// };
};

export default {
	uploadToUndress,
};
