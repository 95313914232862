import styles from "./styles.module.css";
import Footer from "../../components/Footer/Footer";
import useFunnelUndressing from "./useFunnelUndressing";
import Loading from "../Upload/components/Loading/Loading";
import Comment from "../Funnel/components/Comment/Comment";
import Comments from "../Funnel/components/Comments/Comments";
import HeadSeo from "../../components/HeadSeo/HeadSeo";

export default ({
	setUndressComplete,
	scrollPosition,
	undressTypeIsRandom,
	undressComplete,
	isUndressing,
	resetUpload,
	setIsUndressing,
	selectedUndressPhoto,
	resultSrc,
	setResultSrc,
	commentsObj,
}) => {
	const { testimonials, handleUndressingComplete, beforeSrc } = useFunnelUndressing({
		resetUpload,
		setIsUndressing,
		setUndressComplete,
		undressTypeIsRandom,
		scrollPosition,
		undressComplete,
		selectedUndressPhoto,
		resultSrc,
		setResultSrc,
	});
	return (
		<div className={styles.container}>
			<HeadSeo noIndex={true} />
			<div className={styles.samingContainer}>
				<div className={styles.samingTitle}>
					undress<span className={styles.undressAiAi}>AI</span>.com is undressing...
				</div>
				<div className={styles.samingLoadingContainer}>
					<img
						src={selectedUndressPhoto ? URL.createObjectURL(selectedUndressPhoto) : beforeSrc}
						className={styles.undressingImg}
					/>
					<Loading
						isStarted={isUndressing}
						isDone={undressComplete}
						onFinish={handleUndressingComplete}
						expectedCompletionTime={100_000}
						additionalCompletionTime={2000}
						updateInterval={100}
						classNames={[styles.samingLoading]}
					/>
				</div>
			</div>
			<Comments commentsObj={commentsObj} />
		</div>
	);
};
