import React, { useMemo } from "react";
import { animated } from "react-spring";
import classNamesModule from "classnames";
import Skeleton from "react-loading-skeleton";

import useProof from "./useProof";
import _conStyles from "./conStyles";
import styles from "./styles.module.css";

const ImageComparator = ({ beforeImage, afterImage, classNames }) => {
	const { width, styleBefore, styleAfter, styleLine, areImagesLoaded, height } = useProof({
		beforeImage,
		afterImage,
	});
	const conStyles = useMemo($ => _conStyles({ width }), [width]);
	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))}>
			<div style={conStyles.conContainerChild}>
				{!areImagesLoaded && <Skeleton width={width} height={height} baseColor="#232323" />}
				{areImagesLoaded && (
					<>
						<animated.img src={beforeImage} alt="Before undress ai deepnude" style={styleBefore} />
						<animated.img src={afterImage} alt="After undress ai deepnude" style={styleAfter} />
						<animated.div style={styleLine}></animated.div>
					</>
				)}
			</div>
		</div>
	);
};

export default ImageComparator;
