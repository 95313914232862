import { useMemo } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

export default ({ duration, interval, keyID, hideMS }) => {
	const [time, setTime] = useState(duration);
	const startTimeRef = useRef(null);

	// Load duration from localStorage if exists or set initial duration
	useEffect(() => {
		const savedTime = localStorage.getItem(`timer-${keyID}`);
		setTime(savedTime ? Number(savedTime) : duration);
		startTimeRef.current = Date.now() - (duration - Number(savedTime));
	}, [keyID, duration]);

	// Update timer and save to localStorage
	useEffect(() => {
		const update = () => {
			const elapsedTime = Date.now() - startTimeRef.current;
			const newTime = duration - elapsedTime >= 0 ? duration - elapsedTime : 0;

			if (newTime === 0) {
				// Reset the timer
				startTimeRef.current = Date.now();
			}

			localStorage.setItem(`timer-${keyID}`, newTime);
			setTime(newTime);
		};

		update(); // initial update

		const timer = setInterval(update, interval);

		return () => clearInterval(timer);
	}, [interval, keyID, duration]);

	const formatTime = time => {
		const hours = Math.floor(time / 3600000); // Calculate hours
		const min = Math.floor((time % 3600000) / 60000); // Calculate minutes
		const sec = Math.floor((time % 60000) / 1000); // Calculate seconds
		const ms = time % 1000; // Remaining milliseconds

		const msText = hideMS ? "" : ":" + ms;

		if (hours > 0) {
			// Display hours, minutes, seconds, and milliseconds if hours are more than 0
			return `${hours}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}${msText}`;
		} else {
			// Display minutes, seconds, and milliseconds if no hours
			return `${min}:${sec.toString().padStart(2, "0")}${msText}`;
		}
	};

	const formattedTime = useMemo($ => formatTime(time), [time]);

	return { formattedTime };
};
