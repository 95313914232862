import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sleep } from "../../utils/promises";
import axiosInstance from "../../axios/axiosInstance";
import { useUser } from "../../contexts/User/User";
import ai from "../../utils/ai";

export default ({
	resetUpload,
	setIsUndressing,
	setUndressComplete,
	undressTypeIsRandom,
	scrollPosition,
	undressComplete,
	selectedUndressPhoto,
	resultSrc,
	setResultSrc,
}) => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const beforeSrc = searchParams.get("before_src");

	const predefinedResultSrc = searchParams.get("result_src");

	const { setIsLoginPopupOpen } = useUser();

	const handleStart = async () => {
		if (predefinedResultSrc) {
			await sleep(7000);
			setUndressComplete(true);
			setResultSrc(predefinedResultSrc);
		} else {
			const { error, filepath } = await ai.freeUndress({ fileToUndress: selectedUndressPhoto });

			if (error) {
				setIsLoginPopupOpen(true);
				return;
			}

			setUndressComplete(true);
			setIsUndressing(false);
			setResultSrc(`${process.env.REACT_APP_S3_ENDPOINT}/${filepath}`);
		}
	};

	useEffect(() => {
		handleStart();
	}, []);

	const handleUndressingComplete = async () => {
		navigate(`/funnel/result?is_random_girl=${!!predefinedResultSrc}`);
	};

	return {
		beforeSrc,
		handleUndressingComplete,
	};
};
