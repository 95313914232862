import styles from "./styles.module.css";
import Footer from "../../components/Footer/Footer";
import useFunnelResult from "./useFunnelResult";
import LoginPopup from "../Upload/components/LoginPopup/LoginPopup";
import Button from "../../components/Button/Button";
import Comment from "../Funnel/components/Comment/Comment";
import Comments from "../Funnel/components/Comments/Comments";
import HeadSeo from "../../components/HeadSeo/HeadSeo";

export default ({ undressComplete, isUndressing, resetUpload, resultSrc, commentsObj }) => {
	const {
		handleLoginPopupClose,
		showLoginPopup,
		handleDownloadOrRemoveBlur,
		handleUndressAnotherOrDownload,
		imageRef,
		isRandomGirl,
	} = useFunnelResult({ resetUpload });
	return (
		<div className={styles.container}>
			<HeadSeo noIndex={true} />
			<div className={styles.girlsContainer} ref={imageRef}>
				<img src={resultSrc} className={styles.girl} alt={"girl undressed"} />
			</div>
			<div className={styles.buttonsContainer}>
				<>
					<Button
						onClick={handleUndressAnotherOrDownload}
						style={{ backgroundColor: "#22272D" }}
						classNames={[styles.button, styles.leftButton]}>
						{isRandomGirl ? "Undress Another" : "Remove Blur"}
					</Button>
					<Button onClick={handleDownloadOrRemoveBlur} classNames={[styles.button, styles.rightButton]}>
						{isRandomGirl ? "Download" : "Remove Blur"}
					</Button>
				</>
			</div>
			<Comments commentsObj={commentsObj} />
			<LoginPopup isOpen={showLoginPopup} onClose={handleLoginPopupClose} />
		</div>
	);
};
