import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import axiosInstance from "../axios/axiosInstance";

export default () => {
	const [utmCampaign, setUtmCampaign] = useLocalStorage("utmCampaign", null);
	const [searchParams] = useSearchParams();

	useEffect(
		$ => {
			const extractedUtmCampaign = searchParams.get("utm_campaign");

			const extractedS2sToken = searchParams.get("s2");
			const extractedAffiliateID = searchParams.get("affid");
			const s2sUtmCampaign = `TWINRED_${extractedAffiliateID}_${extractedS2sToken}`;

			const utmCampaignToUse = extractedUtmCampaign
				? extractedUtmCampaign
				: extractedS2sToken
				? s2sUtmCampaign
				: null;

			if (utmCampaignToUse && !utmCampaign) {
				setUtmCampaign(utmCampaignToUse);
				axiosInstance.post("/etc/utm-campaign-view", {
					utmCampaign: utmCampaignToUse,
				});
			}
		},
		[searchParams],
	);

	return {};
};
