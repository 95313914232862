import Button from "../Button/Button";
import Popup from "../Popup/Popup";
import styles from "./styles.module.css";
import classNamesModule from "classnames";
import useChristmasPopup from "./useChristmasPopup";
import Timer from "../Timer/Timer";
import { hoursToMs } from "../../utils/time";

export default ({ classNames, style, isOpen, onClose, coinGift, coinBonusGift, dayOfMonth, user, setUser }) => {
	const { handleOpenClick, isLoading, currentStage, handleTopupClick, handleGreatClick } = useChristmasPopup({
		coinGift,
		user,
		setUser,
		onClose,
	});

	return (
		<Popup
			className={classNamesModule(styles.container, ...(classNames || []))}
			style={{ ...style }}
			onClose={onClose}
			isOpen={isOpen}>
			{currentStage === 1 && (
				<div className={styles.stage1Container}>
					<div className={styles.title}>Hoe hoe hoe! ☃️🎅 </div>
					<div className={styles.description}>
						You can open the {dayOfMonth}. door of your advent calendar{" "}
					</div>
					<div className={styles.openButtonAndOlafContainer}>
						<Button classNames={[styles.openButton]} isLoading={isLoading} onClick={handleOpenClick}>
							Open
						</Button>
						<img src="/assets/random/olaf.png" className={styles.olafImg} />
					</div>
				</div>
			)}
			{currentStage === 2 && (
				<div className={styles.stage2Container}>
					<img src="/assets/random/olaf.png" className={styles.olafBigImg} />
					<div className={styles.rightContainer}>
						<div className={styles.olafQuote}>
							“Oh, you you want to open the {dayOfMonth}. advent calendar door? I'm so exited about what
							you might get”
						</div>
						<Button classNames={[styles.yesOpenButton]} onClick={handleOpenClick} isLoading={isLoading}>
							Open
						</Button>
						<Button classNames={[styles.noCloseButton]} isSecondary>
							Close
						</Button>
					</div>
				</div>
			)}
			{currentStage === 3 && (
				<div className={styles.stage1Container}>
					<div className={styles.title}>Congratulations! </div>
					<div className={styles.description}>You got the following</div>
					<div className={styles.giftContainer}>
						<img
							className={styles.giftImg}
							src={coinBonusGift ? "/assets/random/gift.png" : "/assets/random/coin.png"}
						/>
						<div className={styles.giftTitle}>
							{coinBonusGift && `${coinBonusGift * 100}% coin bonus on next topup`}
							{coinGift > 0 && `${coinGift} Coins`}
						</div>
						{coinBonusGift && (
							<div className={styles.giftExpiresIn}>
								Expires in{" "}
								<Timer
									duration={hoursToMs(1)}
									interval={1}
									keyID="christmasGiftExpiresIn"
									classNames={[styles.giftTimer]}
								/>
							</div>
						)}
						{coinBonusGift && (
							<Button classNames={[styles.giftCtaButton]} onClick={handleTopupClick}>
								Topup Now
							</Button>
						)}
						{coinGift > 0 && (
							<Button classNames={[styles.giftCtaButton]} onClick={handleGreatClick}>
								Great
							</Button>
						)}
					</div>
					<div className={styles.olafSalesmanContainer}>
						<img src="/assets/random/olaf.png" className={styles.olafSalesmanImg} />
						<div className={styles.olafSalesmanQuote}>
							"That's awesome!
							{coinBonusGift
								? " Let's use it before it expires"
								: " Let's come back tomorrow to open another door"}
							"
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
};

