import classNamesModule from "classnames";

import useHeader from "./useHeader";
import styles from "./styles.module.css";
import LoginPopup from "../../routes/Upload/components/LoginPopup/LoginPopup";

export default ({ classNames, nativeNavigation }) => {
	const { Items } = useHeader({
		nativeNavigation,
	});

	return (
		<div
			className={classNamesModule(styles.container, ...(classNames || []))}
			style={{
				paddingTop: document.querySelector("#exo-sticky-container-5100564") ? "7rem" : "0",
			}}>
			{Items}
		</div>
	);
};
