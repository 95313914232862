import classNamesModule from "classnames";
import { useMemo } from "react";
import { PhotoView } from "react-photo-view";
import styles from "./styles.module.css";

import Button from "../../../Button/Button";
import _conStyles from "./conStyles";
import useImageMessage from "./useImageMessage";

export default ({
	isAuthor,
	text,
	src,
	isBottom,
	isTop,
	style,
	classNames,
	date,
	onPhotoLoad,
	onCensoredClick,
	alt,
}) => {
	const { timestamp } = useImageMessage({
		src,
		date,
	});
	const conStyles = useMemo(
		$ => _conStyles({ isAuthor, isBottom, isTop, text, onCensoredClick }),
		[isAuthor, isBottom, isTop, text, onCensoredClick],
	);

	return (
		<div
			className={classNamesModule(styles.container, ...(classNames || []))}
			style={{ ...style, ...conStyles.conContainer }}>
			<div className={styles.wrapper} style={{ ...conStyles.conWrapper }}>
				{onCensoredClick && (
					<div className={styles.viewCensoredContainer}>
						<Button
							textStyle={{ padding: ".5rem 1rem" }}
							classNames={[styles.viewCensoredButton]}
							onClick={onCensoredClick}>
							View
						</Button>
					</div>
				)}
				<PhotoView src={src} triggers={onCensoredClick ? [onCensoredClick] : undefined}>
					<img
						src={src}
						className={styles.image}
						style={conStyles.conPhotoView}
						onLoad={$ => onPhotoLoad?.({ isBottom, isTop, isAuthor })}
						alt={alt}
					/>
				</PhotoView>
				{text && (
					<div className={styles.contentContainer} style={conStyles.conContentContainer}>
						<span className={styles.text}>{text}</span>
						<span className={styles.timestamp}>{timestamp}</span>
					</div>
				)}
				{!text && timestamp && (
					<div className={styles.inlineTimestampContainer}>
						<span className={styles.inlineTimestamp}>{timestamp}</span>
					</div>
				)}
			</div>
			{text && (
				<img
					src={isAuthor ? "/assets/chat/penis.png" : "/assets/chat/penisGray.png"}
					className={styles.penis}
					style={conStyles.conImg}
				/>
			)}
		</div>
	);
};
