export const categories = () => [
	[
		{ title: "🍒 Nude", value: "nude", id: 1, isSelected: true },
		{ title: "👙 Bikini", value: "bikini", id: 2, isSelected: false },
	],
	[
		{ title: "🥵 Lingerie", value: "lingerie", id: 3, isSelected: false },
		{ title: "🩱 Swimsuit", value: "swimsuit", id: 4, isSelected: false },
	],
	[
		{ title: "🤸‍♀️ Sportswear", value: "sport", id: 5, isSelected: false },
		{ title: "👩‍💼 Business", value: "business", id: 6, isSelected: false },
	],
];

export default {
	categories,
};
