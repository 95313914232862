import Button from "../Button/Button";
import Popup from "../Popup/Popup";
import styles from "./styles.module.css";
import classNamesModule from "classnames";

export default ({ classNames, style, isOpen, onClose }) => {
	return (
		<Popup
			className={classNamesModule(styles.container, ...(classNames || []))}
			style={{ ...style }}
			onClose={onClose}
			isOpen={isOpen}>
			<div className={styles.title}>She couldn't send a photo to you 🫣</div>
			<div className={styles.description}>
				Try creating a new girl where the face is well visible in the photo. If it still doesn't work, contact
				our livechat support.
			</div>
			<Button
				onClick={$ => {
					// window.location.reload();
					window.location.href = "/new/getphoto?destination=chat";
				}}
				classNames={[styles.refreshButton]}>
				Create New Girl
			</Button>
			<Button
				onClick={$ => {
					window.location.href = "/upload?support";
				}}
				isSecondary>
				Contact Livechat Support
			</Button>
		</Popup>
	);
};
