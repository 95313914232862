import Button from "../../components/Button/Button";
import LoginPopup from "../Upload/components/LoginPopup/LoginPopup";
import styles from "./styles.module.css";
import classNamesModule from "classnames";
import useFreeUndressResult from "./useFreeUndressResult";

export default ({ classNames, style }) => {
	const { handleCtaClick, handleLoginPopupClose, isLoginPopupShown, searchParams } = useFreeUndressResult();

	return (
		<div className={classNamesModule(styles.container, ...(classNames || []))} style={{ ...style }}>
			<img className={styles.img} src={`${process.env.REACT_APP_S3_ENDPOINT}/${searchParams.get("filepath")}`} />
			<div className={styles.buttonContainer}>
				<Button classNames={[styles.button]} onClick={handleCtaClick} isSecondary={true}>
					Remove Blur
				</Button>
				<Button classNames={[styles.button]} onClick={handleCtaClick}>
					Download
				</Button>
			</div>
			<LoginPopup isOpen={isLoginPopupShown} onClose={handleLoginPopupClose} />
		</div>
	);
};
