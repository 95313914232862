import { useMemo, useState } from "react";

export default ({ girls }) => {
	const [currentGirlIndex, setCurrentGirlIndex] = useState(0);

	const handleNextClick = () => {
		setCurrentGirlIndex(before => (before === girls.length - 1 ? 0 : before + 1));
	};

	const handlePrevClick = () => {
		setCurrentGirlIndex(before => (before === 0 ? girls.length - 1 : before - 1));
	};

	const currentGirl = useMemo($ => girls[currentGirlIndex]);

	return {
		currentGirlIndex,
		handleNextClick,
		handlePrevClick,
		currentGirl,
	};
};
