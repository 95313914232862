import { createContext, useContext, useEffect, useRef, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import styles from "./styles.module.css";
import { toast } from "react-toastify";
import axiosInstance from "../../axios/axiosInstance";
import Button from "../../components/Button/Button.jsx";
import ChristmasPopup from "../../components/ChristmasPopup/ChristmasPopup.jsx";
import Popup from "../../components/Popup/Popup.jsx";
import i18n from "../../translation/i18n.js";
import _ from "lodash";
import { msSinceDate, msToMinutes } from "../../utils/time.js";
import WebAppInfo from "../../components/WebAppInfo/WebAppInfo.jsx";
import Banner from "../../components/WebAppInfo/components/Banner/Banner.jsx";
import WebappPopup from "../../components/WebAppInfo/components/Popup/Popup.jsx";
import { submitGtagEvent } from "../../utils/gtag.js";
import { Helmet } from "react-helmet";
import { PRODUCTION } from "../../constants/general.js";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup.jsx";
import LoginPopup from "../../routes/Upload/components/LoginPopup/LoginPopup.jsx";
import useTracking from "../../uses/useTracking.js";

const UserContext = createContext();

const UserProvider = ({ children }) => {
	const [user, setUser] = useState({ isLoggedIn: false });
	const [isLoadingUser, setIsLoadingUser] = useState(true);

	const userRef = useRef({ isLoggedIn: false });
	const isLoadingUserRef = useRef(true);

	const [ttoken, setTtoken] = useLocalStorage("ttoken", null);
	const [isAdVersion, setIsAdVersion] = useLocalStorage("isAdVersion", null);

	const [showWebappPopup, setShowWebappPopup] = useState(false);

	const location = useLocation();

	useEffect(
		$ => {
			userRef.current = user;
		},
		[user],
	);

	useEffect(
		$ => {
			isLoadingUserRef.current = isLoadingUser;
		},
		[isLoadingUser],
	);

	const getUser = async ({ abortControlSignal }) => {
		try {
			const { data } = await axiosInstance.get("/tauth/user", {
				signal: abortControlSignal,
			});

			return { user: data.user };
		} catch (err) {
			return { user: null };
		}
	};

	const refreshUser = async () => {
		const abortController = new AbortController();
		const { user } = await getUser({
			abortControlSignal: abortController.signal,
		});
		setUser({ ...user, isLoggedIn: !!user });
	};

	const [lang, setLang] = useLocalStorage("undresslanguage", "en");

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const changeLanguage = language => {
		i18n.changeLanguage(language);
		setLang(language);
		localStorage.setItem("undresslanguage", language);
	};

	const changeLanguageInDropDown = e => {
		let dropdownLanguage = e.target.value;
		changeLanguage(dropdownLanguage);
		if (user.isLoggedIn) {
			axiosInstance.put("/user/update", {
				language: dropdownLanguage,
			});
		}
	};

	const [params, setParams] = useSearchParams();

	useEffect(
		$ => {
			if (!ttoken && !oauthAccessToken && !oauthRefreshToken) {
				setIsLoadingUser(false);
				window.gtag?.("config", window.location.host.includes("undressai") ? "G-S4Y95LKBT0" : "XXXSEX", {
					user_properties: {
						user_is_logged_in: false,
					},
				});
				return;
			}
			setIsLoadingUser(true);
			const abortController = new AbortController();
			getUser({
				abortControlSignal: abortController.signal,
			})
				.then(({ user }) => {
					setUser({ ...user, isLoggedIn: !!user });

					try {
						const clonedUser = _.cloneDeep(user);
						const gtagUser = {};

						Object.entries(clonedUser).map(([key, value], i) => {
							if (
								[
									"orders",
									"isStripe",
									"isAnimateFaceBetaTester",
									"country",
									"metadata",
									"hideInLeaderboard",
									"remindedAt",
									"freeGenerateCount",
									"isGenerating",
									"telegramFirstname",
									"email",
								].includes(key)
							) {
								return;
							}

							if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
								gtagUser[key] = value;
								return;
							}

							if (Array.isArray(value)) {
								gtagUser[key + "_count"] = value.length;
								return;
							}
						});

						gtagUser["created_minutes_ago"] = msToMinutes(msSinceDate(new Date(clonedUser.createdAt)));

						gtagUser["paid_orders_count"] = clonedUser.orders.filter(o => o.dollarAmount > 0).length;

						gtagUser["revenue"] = clonedUser.orders.reduce((acc, o) => acc + (o.dollarAmount || 0), 0);

						let userActiveOffer = null;
						const userLastCustomOffer = _.last(clonedUser.offers);
						if (userLastCustomOffer) {
							const offerExpired = new Date() > new Date(userLastCustomOffer?.expiryDate);
							const offerRedeemLimitReached =
								clonedUser.orders
									.filter(o => o.dollarAmount > 0)
									.filter(o => new Date(o.date) > new Date(userLastCustomOffer?.date)).length >=
								userLastCustomOffer?.maxRedeems;
							if (!offerExpired && !offerRedeemLimitReached) {
								userActiveOffer = userLastCustomOffer;
							}
						}
						gtagUser["active_offer"] = userActiveOffer;

						gtagUser["is_logged_in"] = true;

						gtagUser["adventC_count"] = clonedUser.openedDoorDayOfMonths.length;

						const gtagUserWithUserPrefix = {};
						Object.entries(gtagUser).map(([key, value], i) => {
							gtagUserWithUserPrefix["user_" + key] = value;
						});

						// window.gtag?.(
						// 	"config",
						// 	window.location.host.includes("undressai") ? "G-S4Y95LKBT0" : "XXXSEX",
						// 	{ user_properties: gtagUserWithUserPrefix },
						// );
						window.gtag?.("set", "user_properties", gtagUserWithUserPrefix);
						window.gtag?.("set", "user_id", user.telegramID);
					} catch (err) {
						console.log("error in set g tag in user context lol", err);
					}

					if (location.pathname.includes("/result")) {
						params.delete("ttoken");
						setParams(params);
					}
					changeLanguage(user.language);

					if (user?.telegramID) {
						setTimeout($ => {
							window?.LC_API?.set_custom_variables?.([
								{ name: "telegramID", value: user.telegramID },
								{ name: "fourbasedUsername", value: user.fourbasedUsername || "not set" },
								{ name: "country", value: user.country || "unknown" },
								{ name: "coins", value: user.coins },
								{
									name: "is paid user",
									value: user.orders.find(o => o.dollarAmount > 0) ? "yes" : "no",
								},
							]);

							window.Intercom?.("update", {
								// telegramID: user.telegramID,
								// fourbasedUsername: user.fourbasedUsername,
								// country: user.country,
								// coins: user.coins,
								// isPaidUser: user.orders.find(o => o.dollarAmount > 0) ? "yes" : "no",
								user_id: user.telegramID,
							});
						}, 2000);
					}
				})
				.catch(err => {
					setUser({ isLoggedIn: false });
					window.gtag?.("config", window.location.host.includes("undressai") ? "G-S4Y95LKBT0" : "XXXSEX", {
						user_properties: {
							user_is_logged_in: false,
						},
					});
				})
				.finally($ => setIsLoadingUser(false));

			return () => {
				abortController.abort();
			};
		},
		[ttoken, location.pathname],
	);

	useEffect(
		$ => {
			if (params.has("ttoken")) {
				setTtoken(params.get("ttoken"));
			}
		},
		[params],
	);

	// ADS >>>>

	const showAds =
		(user?.isAdVersion || isAdVersion) &&
		user?.coins < 18 &&
		!["/deposit", "/recharge", "/recharge/payment-method", "/recharge/4basedpay", "/recharge/cryptopay"].includes(
			location.pathname,
		);

	// INSTANT MESSAGE
	// useEffect(() => {
	// if (!showAds) {
	// 	return;
	// }

	// 	// Create the script element for ad-provider.js
	// 	const scriptElement1 = document.createElement("script");
	// 	scriptElement1.async = true;
	// 	scriptElement1.type = "application/javascript";
	// 	scriptElement1.src = "https://a.magsrv.com/ad-provider.js";

	// 	// Create the 'ins' element
	// 	const insElement = document.createElement("ins");
	// 	insElement.className = "eas6a97888e";
	// 	insElement.dataset.zoneid = oxe.INSTANT_MESSAGE_ZONE_ID;

	// 	// Create the script element for AdProvider
	// 	const scriptElement2 = document.createElement("script");
	// 	scriptElement2.type = "application/javascript";
	// 	scriptElement2.innerHTML = '(AdProvider = window.AdProvider || []).push({"serve": {}});';

	// 	// Append elements to the body or a specific element in your app
	// 	document.body.appendChild(scriptElement1);
	// 	document.body.appendChild(insElement);
	// 	document.body.appendChild(scriptElement2);

	// 	// Cleanup when component is unmounted
	// 	return () => {
	// 		document.body.removeChild(scriptElement1);
	// 		document.body.removeChild(insElement);
	// 		document.body.removeChild(scriptElement2);
	// 	};
	// }, [showAds]);

	// VIDEO SLIDER
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the script element for video-slider.js
		const scriptElement1 = document.createElement("script");
		scriptElement1.type = "application/javascript";
		scriptElement1.src = "https://a.magsrv.com/video-slider.js";

		// Create the script element for adConfig and VideoSlider.init
		const scriptElement2 = document.createElement("script");
		scriptElement2.type = "application/javascript";
		scriptElement2.innerHTML = `
		  var adConfig = {
			"idzone": ${process.env.REACT_APP_EXO_VIDEO_SLIDER_ZONE_ID},
			"frequency_period": 0,
			"close_after": 5,
			"on_complete": "repeat",
			"screen_density": 25,
			"cta_enabled": 1
		  };
		  VideoSlider.init(adConfig);
		`;

		// Append elements to the body or a specific element in your app
		document.body.appendChild(scriptElement1);

		// Add an event listener to execute the second script after the first script is loaded
		scriptElement1.onload = () => {
			document.body.appendChild(scriptElement2);
		};

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(scriptElement1);
			document.body.removeChild(scriptElement2);
		};
	}, [showAds]);

	// DESKOTP POPUUNDER
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the inline script element for ad configurations
		const inlineScriptElement = document.createElement("script");
		inlineScriptElement.type = "application/javascript";
		inlineScriptElement.innerHTML = `
		  var ad_idzone = "${process.env.REACT_APP_EXO_DESKTOP_POPUNDER_ZONE_ID}",
		  ad_popup_fallback = true,
		  ad_popup_force = false,
		  ad_chrome_enabled = true,
		  ad_new_tab = false,
		  ad_frequency_period = 1,
		  ad_frequency_count = 1,
		  ad_trigger_method = 2,
		  ad_trigger_class = "link",
		  ad_trigger_delay = 0;
		`;

		// Create the script element for popunder1000.js
		const externalScriptElement = document.createElement("script");
		externalScriptElement.type = "application/javascript";
		externalScriptElement.src = "https://a.pemsrv.com/popunder1000.js";

		// Append elements to the body or a specific element in your app
		document.body.appendChild(inlineScriptElement);
		document.body.appendChild(externalScriptElement);

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(inlineScriptElement);
			document.body.removeChild(externalScriptElement);
		};
	}, [showAds]);

	// DESKTOP BANNER
	// useEffect(() => {
	// if (!showAds) {
	// 	return;
	// }

	// 	// Create the script element for ad-provider.js
	// 	const scriptElement1 = document.createElement("script");
	// 	scriptElement1.async = true;
	// 	scriptElement1.type = "application/javascript";
	// 	scriptElement1.src = "https://a.magsrv.com/ad-provider.js";

	// 	// Create the 'ins' element
	// 	const insElement = document.createElement("ins");
	// 	insElement.className = "eas6a97888e";
	// 	insElement.dataset.zoneid = oxe.DESKTOP_BANNER_ZONE_ID;

	// 	// Create the script element for AdProvider
	// 	const scriptElement2 = document.createElement("script");
	// 	scriptElement2.type = "application/javascript";
	// 	scriptElement2.innerHTML = '(AdProvider = window.AdProvider || []).push({"serve": {}});';

	// 	// Append elements to the body or a specific element in your app
	// 	document.body.appendChild(scriptElement1);
	// 	document.body.appendChild(insElement);
	// 	document.body.appendChild(scriptElement2);

	// 	// Cleanup when component is unmounted
	// 	return () => {
	// 		document.body.removeChild(scriptElement1);
	// 		document.body.removeChild(insElement);
	// 		document.body.removeChild(scriptElement2);
	// 	};
	// }, [showAds]);

	// FULL PAGE DESKTOP
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the script element for fp-interstitial.js
		const scriptElement = document.createElement("script");
		scriptElement.type = "application/javascript";
		scriptElement.dataset.idzone = process.env.REACT_APP_EXO_FULLPAGE_DESKTOP_ZONE_ID;
		scriptElement.dataset.adFirstTriggerClicks = "3";
		scriptElement.dataset.adNextTriggerClicks = "3";
		scriptElement.dataset.type = "desktop";
		scriptElement.dataset.browserSettings = "1";
		scriptElement.dataset.adTriggerMethod = "2";
		scriptElement.dataset.adTriggerClass = "link";
		scriptElement.src = "https://a.pemsrv.com/fp-interstitial.js";

		// Append the script element to the body or a specific element in your app
		document.body.appendChild(scriptElement);

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(scriptElement);
		};
	}, [showAds]);

	// MOBILE BANNER
	// useEffect(() => {
	// if (!showAds) {
	// 	return;
	// }

	// 	// Create the script element for ad-provider.js
	// 	const scriptElement1 = document.createElement("script");
	// 	scriptElement1.async = true;
	// 	scriptElement1.type = "application/javascript";
	// 	scriptElement1.src = "https://a.magsrv.com/ad-provider.js";

	// 	// Create the 'ins' element
	// 	const insElement = document.createElement("ins");
	// 	insElement.className = "eas6a97888e";
	// 	insElement.dataset.zoneid = oxe.MOBILE_BANNER_ZONE_ID;

	// 	// Create the script element for AdProvider
	// 	const scriptElement2 = document.createElement("script");
	// 	scriptElement2.type = "application/javascript";
	// 	scriptElement2.innerHTML = '(AdProvider = window.AdProvider || []).push({"serve": {}});';

	// 	// Append elements to the body or a specific element in your app
	// 	document.body.appendChild(scriptElement1);
	// 	document.body.appendChild(insElement);
	// 	document.body.appendChild(scriptElement2);

	// 	// Cleanup when component is unmounted
	// 	return () => {
	// 		document.body.removeChild(scriptElement1);
	// 		document.body.removeChild(insElement);
	// 		document.body.removeChild(scriptElement2);
	// 	};
	// }, [showAds]);

	// MOBILE POPUNDER
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the inline script element for ad configurations
		const inlineScriptElement = document.createElement("script");
		inlineScriptElement.type = "application/javascript";
		inlineScriptElement.innerHTML = `
		  var ad_idzone = "${process.env.REACT_APP_EXO_MOBILE_POPUNDER_ZONE_ID}",
		  ad_popup_fallback = true,
		  ad_popup_force = false,
		  ad_chrome_enabled = true,
		  ad_new_tab = false,
		  ad_frequency_period = 1,
		  ad_frequency_count = 1,
		  ad_trigger_method = 2,
		  ad_trigger_class = "link",
		  ad_trigger_delay = 0;
		`;

		// Create the script element for popunder1000.js
		const externalScriptElement = document.createElement("script");
		externalScriptElement.type = "application/javascript";
		externalScriptElement.src = "https://a.pemsrv.com/popunder1000.js";

		// Append elements to the body or a specific element in your app
		document.body.appendChild(inlineScriptElement);
		document.body.appendChild(externalScriptElement);

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(inlineScriptElement);
			document.body.removeChild(externalScriptElement);
		};
	}, [showAds]);

	// MOBILE INSTANT MESSAGE
	// useEffect(() => {
	// if (!showAds) {
	// 	return;
	// }

	// 	// Create the script element for ad-provider.js
	// 	const scriptElement1 = document.createElement("script");
	// 	scriptElement1.async = true;
	// 	scriptElement1.type = "application/javascript";
	// 	scriptElement1.src = "https://a.magsrv.com/ad-provider.js";

	// 	// Create the 'ins' element
	// 	const insElement = document.createElement("ins");
	// 	insElement.className = "eas6a97888e";
	// 	insElement.dataset.zoneid = oxe.MOBILE_INSTANT_MESSAGE_ZONE_ID;

	// 	// Create the script element for AdProvider
	// 	const scriptElement2 = document.createElement("script");
	// 	scriptElement2.type = "application/javascript";
	// 	scriptElement2.innerHTML = '(AdProvider = window.AdProvider || []).push({"serve": {}});';

	// 	// Append elements to the body or a specific element in your app
	// 	document.body.appendChild(scriptElement1);
	// 	document.body.appendChild(insElement);
	// 	document.body.appendChild(scriptElement2);

	// 	// Cleanup when component is unmounted
	// 	return () => {
	// 		document.body.removeChild(scriptElement1);
	// 		document.body.removeChild(insElement);
	// 		document.body.removeChild(scriptElement2);
	// 	};
	// }, [showAds]);

	// MOBILE FULLPAGE
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the script element for fp-interstitial.js
		const scriptElement = document.createElement("script");
		scriptElement.type = "application/javascript";
		scriptElement.src = "https://a.pemsrv.com/fp-interstitial.js";
		scriptElement.dataset.idzone = process.env.REACT_APP_EXO_MOBILE_FULLPAGE_ZONE_ID;
		scriptElement.dataset.ad_first_trigger_clicks = "3";
		scriptElement.dataset.ad_next_trigger_clicks = "3";
		scriptElement.dataset.type = "mobile";
		scriptElement.dataset.browser_settings = "1";
		scriptElement.dataset.ad_trigger_method = "2";
		scriptElement.dataset.ad_trigger_class = "link";

		// Append the script element to the body or a specific element in your app
		document.body.appendChild(scriptElement);

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(scriptElement);
		};
	}, [showAds]);

	// NATIVE WIDGET
	useEffect(() => {
		if (!showAds) {
			return;
		}

		// Create the script element for ad-provider.js
		const scriptElement1 = document.createElement("script");
		scriptElement1.async = true;
		scriptElement1.type = "application/javascript";
		scriptElement1.src = "https://a.magsrv.com/ad-provider.js";

		// Create the 'ins' element
		const insElement = document.createElement("ins");
		insElement.className = "eas6a97888e";
		insElement.dataset.zoneid = process.env.REACT_APP_EXO_NATIVE_WIDGET_ZONE_ID;

		// Create the script element for AdProvider
		const scriptElement2 = document.createElement("script");
		scriptElement2.type = "application/javascript";
		scriptElement2.innerHTML = '(AdProvider = window.AdProvider || []).push({"serve": {}});';

		// Append elements to the body or a specific element in your app
		document.body.appendChild(scriptElement1);
		document.body.appendChild(insElement);
		document.body.appendChild(scriptElement2);

		// Cleanup when component is unmounted
		return () => {
			document.body.removeChild(scriptElement1);
			document.body.removeChild(insElement);
			document.body.removeChild(scriptElement2);
		};
	}, [showAds]);

	// HIDE ADS ON DEPOSIT PAGES

	useEffect(
		$ => {
			if (!showAds) {
				const elements = document.querySelectorAll("[class^='exo']");
				elements.forEach(element => {
					element.style.display = "none";
				});
			}
		},
		[location],
	);

	// <<<<<<<

	const [utmCampaign, setUtmCampaign] = useLocalStorage("utmCampaign", null);
	const [searchParams] = useSearchParams();

	const {} = useTracking();

	const getAlgoN = async () => {
		const res = await axiosInstance.get("/algon", {
			params: { route: location.pathname },
		});

		return res?.data?.algoN;
	};

	const CloseButton = ({ closeToast }) => (
		<div
			onClick={closeToast}
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				backgroundColor: "#171d24",
				padding: "0 1rem",
			}}>
			<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_187_2)">
					<path
						d="M9.28044 7.5001L14.8516 1.92925C15.0494 1.73142 15.0494 1.41129 14.8516 1.21352L13.7869 0.148395C13.6921 0.0536304 13.5634 0.000274658 13.4291 0.000274658C13.2947 0.000274658 13.166 0.0536304 13.0712 0.148395L7.50001 5.71966L1.92881 0.148395C1.83398 0.0536304 1.70529 0.000274658 1.57094 0.000274658C1.43663 0.000274658 1.30791 0.0536304 1.21311 0.148395L0.148372 1.21352C-0.0494574 1.41129 -0.0494574 1.73142 0.148372 1.92925L5.71957 7.50013L0.148926 13.0703C-0.0488064 13.2681 -0.0488064 13.5883 0.148926 13.786L1.21373 14.8512C1.30849 14.9459 1.43721 14.9993 1.57156 14.9993C1.70591 14.9993 1.8346 14.9459 1.92946 14.8512L7.49998 9.28056L13.0705 14.8512C13.1654 14.9459 13.294 14.9993 13.4284 14.9993C13.5628 14.9993 13.6915 14.9459 13.7862 14.8512L14.851 13.786C15.0488 13.5883 15.0488 13.2681 14.851 13.0703L9.28044 7.5001Z"
						fill="#868686" // Lighter color for the cross
					/>
				</g>
				<defs>
					<clipPath id="clip0_187_2">
						<rect width="15" height="15" fill="#171d24" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);

	const navigate = useNavigate();

	const [showAlgonPopup, setShowAlgonPopup] = useState(false);
	const [algoN, setAlgoN] = useState(null);

	const [isChristmasOpen, setIsChristmasOpen] = useState(false);

	const getDoor = async () => {
		const res = await axiosInstance.get("/etc/christmas/get-door", {
			params: {
				dayOfMonth: new Date().getDate(),
			},
		});

		return res.data;
	};

	const [door, setDoor] = useState(null);

	useEffect($ => {
		// getDoor().then(({ door, hasAlreadyOpened }) => {
		// 	if (door && !hasAlreadyOpened) {
		// 		setIsChristmasOpen(true);
		// 		setDoor(door);
		// 	}
		// });
	}, []);

	const handleChristmasPopupClose = () => {
		setIsChristmasOpen(false);
	};

	///// OAUTH

	const [oauthRefreshToken, setOauthRefreshToken] = useLocalStorage("oauthRefreshToken", null);
	const [oauthAccessToken, setOauthAccessToken] = useLocalStorage("oauthAccessToken", null);
	const [authMethod, setAuthType] = useLocalStorage("authMethod", "telegram");

	const getToken = async ({ code, abortControllerSignal, ref, uref }) => {
		try {
			const res = await axiosInstance.post(
				"/oauth/code",
				{ code, origin: window.location.href.split("?")[0], utmCampaign, authMethod, ref, uref },
				{ signal: abortControllerSignal },
			);

			return {
				accessToken: res.data.accessToken,
				refreshToken: res.data.refreshToken,
			};
		} catch (err) {
			console.log("error", err);
			return { accessToken: null, refreshToken: null };
		}
	};

	const getAndSetToken = async ({ code, abortControllerSignal, ref, uref }) => {
		const { accessToken, refreshToken } = await getToken({ code, abortControllerSignal, ref, uref });

		console.log("Setting aaccess token", accessToken);

		setOauthAccessToken(accessToken);
		setOauthRefreshToken(refreshToken);
	};

	const handleOauthCode = async ({ code, abortControllerSignal }) => {
		console.log("Handling oauth code", { "searchParams.get(state)": searchParams.get("state") });

		let parameter = {};

		if (searchParams.get("state")) {
			parameter = searchParams
				.get("state")
				.split("-")
				.reduce(
					(parameter, x) => ({
						...parameter,
						[x.split("=")[0]]: x.split("=")[1],
					}),
					{},
				);
		}

		console.log({ parameter: JSON.stringify(parameter, null, 2) });

		await getAndSetToken({ code, abortControllerSignal, ref: parameter.ref, uref: parameter.uref });

		console.log({ parameter });

		if (parameter.chataigirlid) {
			try {
				const res = await axiosInstance.post("/girl/create-from-public-girl", {
					publicGirlID: parameter.chataigirlid,
				});

				const { newGirlID } = res.data;

				window.location.href = "/chat?girlid=" + newGirlID;

				return;
			} catch (err) {
				console.log("error in creating public girl id on oauth callback", err);
			}
		}

		window.location.href = parameter.maloumusername
			? `/recharge?maloum_username=${parameter.maloumusername}`
			: parameter.chatai
			? "/chats"
			: "/upload";
	};

	useEffect($ => {
		const params = new URLSearchParams(window.location.search);

		if (!params.has("code")) {
			return;
		}

		const code = params.get("code");

		const abortController = new AbortController();

		handleOauthCode({
			code,
			abortControllerSignal: abortController.signal,
			// type: params.get("state")?.includes("discord") ? "discord" : "google",
		});

		return () => {
			abortController.abort();
		};
	}, []);

	///////

	useEffect(
		$ => {
			if (!user?.isLoggedIn) {
				return;
			}

			getAlgoN().then(algoN => {
				if (!algoN || algoN?.forbiddenRoutes?.includes(location?.pathname)) {
					return;
				}

				setAlgoN(algoN);

				submitGtagEvent("algon_click", {
					algon_id: algoN?.id,
				});

				let userMetadata = {};
				try {
					userMetadata = JSON.parse(user.metadata);
				} catch (e) {}

				if (
					algoN.type === "undressappinfo" &&
					(userMetadata.webappClickCount < 2 || !userMetadata.webappClickCount)
				) {
					toast(
						<Banner
							classNames={[styles.banner]}
							onGetClick={$ => {
								setShowWebappPopup(true);

								axiosInstance.post("/user/update-metadata", {
									metadata: {
										webappClickCount: (userMetadata.webappClickCount || 0) + 1,
									},
								});
							}}
						/>,
						{
							autoClose: false,
							position: "bottom-left",
							// style: { backgroundColor: "transparent" },
							closeButton: CloseButton,
							style: {
								padding: 0,
							},
							bodyStyle: {
								padding: 0,
							},
							closeOnClick: false,
							toastId: "algon",
						},
					);
				}

				if (algoN.type === "popup") {
					setShowAlgonPopup(true);
				}

				if (algoN.type === "toast") {
					toast(
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								backgroundColor: "#171D24",
							}}
							onClick={$ => {
								window.location.href = algoN?.ctaRoute;
							}}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<img
									src={`${process.env.REACT_APP_S3_ENDPOINT}/${algoN?.photo}`}
									style={{
										height: "3rem",
										width: "3rem",
										objectFit: "cover",
										borderRadius: "50%",
										marginRight: ".7rem",
									}}
								/>
								<div style={{ paddingRight: ".7rem", fontSize: "1.1rem" }}>{algoN?.text}</div>
							</div>
							<Button
								textStyle={{ whiteSpace: "nowrap" }}
								onClick={$ => {
									navigate;
								}}>
								{algoN?.ctaText}
							</Button>
						</div>,
						{
							autoClose: false,
							position: "bottom-left",
							style: { backgroundColor: "#171D24" },
							closeButton: CloseButton,
							toastId: "algon",
						},
					);
				}
			});
		},
		[user],
	);

	useEffect(
		$ => {
			toast?.dismiss?.("algon");
		},
		[location.pathname],
	);

	const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

	const handleErrorPopupClose = () => {
		setIsErrorPopupOpen(false);
	};

	useEffect(
		$ => {
			const livechatContainer = document.querySelector("#chat-widget-container");

			// change position to absolute
			if (livechatContainer) {
				livechatContainer.style.transform = algoN ? `translateY(-3.5rem)` : undefined;
			}
		},
		[algoN],
	);

	useEffect(
		$ => {
			if (location.pathname.includes("/paint")) {
				window.LC_API?.hide_chat_window?.();
			}
		},
		[location],
	);

	const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

	const handleLoginPopupClose = () => {
		setIsLoginPopupOpen(false);
	};

	useEffect($ => {
		axiosInstance.get("/etc/ping").catch(err => {
			toast("Server not reachable, we are working on it! Try again in 1-2 hours 🫡", {
				bodyStyle: { color: "#6E49FF" },
				hideProgressBar: true,
			});
		});
	}, []);

	const getAndSetCountry = async () => {
		await axiosInstance.post("/user/update-country");
	};

	useEffect($ => {
		getAndSetCountry();
	}, []);

	return (
		<I18nextProvider i18n={i18n}>
			{PRODUCTION && (
				<Helmet>
					{/* <script>
						{`(function (h, o, t, j, a, r) {
				h.hj =
					h.hj ||
					function () {
						(h.hj.q = h.hj.q || []).push(arguments);
					};
				h._hjSettings = { hjid: 3713498, hjsv: 6 };
				a = o.getElementsByTagName("head")[0];
				r = o.createElement("script");
				r.async = 1;
				r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
				a.appendChild(r);
			})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`}
					</script> */}
					{/* <script>
						{`

				var w = window;
				var ic = w.Intercom;
				if (typeof ic === "function") {
					ic("reattach_activator");
					ic("update", w.intercomSettings);
				} else {
					var d = document;
					var i = function () {
						i.c(arguments);
					};
					i.q = [];
					i.c = function (args) {
						i.q.push(args);
					};
					w.Intercom = i;
					var l = function () {
						var s = d.createElement("script");
						s.type = "text/javascript";
						s.async = true;
						s.src = "https://widget.intercom.io/widget/lim17lty";
						var x = d.getElementsByTagName("script")[0];
						x.parentNode.insertBefore(s, x);
					};
					if (document.readyState === "complete") {
						l();
					} else if (w.attachEvent) {
						w.attachEvent("onload", l);
					} else {
						w.addEventListener("load", l, false);
					}
				}
          `}
					</script> */}
				</Helmet>
			)}
			<ChristmasPopup
				isOpen={isChristmasOpen}
				coinGift={door?.coins}
				onClose={handleChristmasPopupClose}
				coinBonusGift={door?.offer?.extraCoinFactor}
				dayOfMonth={door?.dayOfMonth}
				user={user}
				setUser={setUser}
			/>
			{/* <WebAppInfo /> */}
			<WebappPopup isOpen={showWebappPopup} onClose={$ => setShowWebappPopup(false)} />
			<Popup isOpen={showAlgonPopup} onClose={$ => setShowAlgonPopup(false)}>
				{algoN && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "space-between",
							height: "100%",
						}}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "space-between",
								height: "100%",
								paddingBottom: ".5rem",
							}}>
							<img
								src={`${process.env.REACT_APP_S3_ENDPOINT}/${algoN?.photo}`}
								style={{
									height: "3rem",
									width: "3rem",
									objectFit: "cover",
									borderRadius: "50%",
									marginBottom: ".5rem",
								}}
							/>
							<div
								style={{
									fontSize: "1.5rem",
									// fontFamily: "SF Bold",
									fontWeight: "bold",
									textAlign: "center",
									marginBottom: ".5rem",
								}}>
								{algoN?.title}
							</div>
							<div style={{ fontSize: "1.1rem", textAlign: "center", marginBottom: ".7rem" }}>
								{algoN?.description}
							</div>
						</div>
						<Button
							style={{ width: "100%" }}
							onClick={$ => {
								setShowAlgonPopup(false);
								navigate(algoN?.ctaRoute);
							}}>
							{algoN?.ctaText}
						</Button>
					</div>
				)}
			</Popup>
			<ErrorPopup isOpen={isErrorPopupOpen} onClose={handleErrorPopupClose} />
			<UserContext.Provider
				value={{
					user,
					setUser,
					isLoadingUser,
					changeLanguage,
					lang,
					changeLanguageInDropDown,
					isLoadingUserRef,
					userRef,
					setIsErrorPopupOpen,
					refreshUser,
					setIsLoginPopupOpen,
				}}>
				<div>{children}</div>
			</UserContext.Provider>
			<LoginPopup isOpen={isLoginPopupOpen} onClose={handleLoginPopupClose} closeOnOutsideClick />
		</I18nextProvider>
	);
};

const useUser = $ => useContext(UserContext);

export default UserProvider;

export { UserProvider, useUser };
